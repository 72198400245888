import { mapActions, mapGetters } from "vuex";
import aclAccess from "@/mixins/hasAccess";

export default {
  mixins: [aclAccess],
  data() {
    return {
      navMenuItems: [
        {
          url: "/Home",
          name: "الرئيسية",
          icon: "HomeIcon",
          i18n: "home",
        },
        {
          header: "الطرود",
          icon: "PackageIcon",
          i18n: "orders",
          items: [
            {
              url: "/UncompletedOrder",
              name: "الطرود الغير مكتملة",
              slug: "order",
              i18n: "uncompletedOrder",
              icon: "CircleIcon",
            },
            {
              url: "/SavedOrder",
              name: "المحفوظة قبل الشحن",
              slug: "order",
              i18n: "saveBeforeShip",
              icon: "CircleIcon",
            },
            {
              url: "/SentOrder",
              name: "المرسلة للشحن",
              slug: "order",
              i18n: "sentForShip",
              icon: "CircleIcon",
            },
            {
              url: "/DelayOrder",
              name: "المؤجلة",
              slug: "order",
              i18n: "delayed",
              icon: "CircleIcon",
            },
            {
              url: "/UnderwayOrder",
              name: "قيد التنفيذ",
              slug: "order",
              i18n: "underWay",
              icon: "CircleIcon",
            },
            {
              url: "/WithCaptain",
              name: this.hambozoName,
              slug: "order",
              i18n: this.hambozoName,
              icon: "CircleIcon",
            },
            {
              url: "/GivenOrder",
              name: "المسلمة",
              slug: "order",
              i18n: "given",
              icon: "CircleIcon",
            },
            {
              url: "/Supplied",
              name: "الموردة",
              slug: "order",
              i18n: "supplied",
              icon: "CircleIcon",
            },
          ],
        },
        {
          url: "/Missions",
          name: "المهمات",
          icon: "ClipboardIcon",
          i18n: "missions",
        },
        {
          header: "التكاليف",
          icon: "DollarSignIcon",
          i18n: "costs",
          isNew: true,
          items: [
            {
              url: "/ClientCosts",
              name: "تكاليف الشحن",
              slug: "Costs",
              icon: "MapIcon",
              i18n: "client_costs",
              // isNew: true,
            },
            {
              url: "/ClientOfficeCosts",
              name: "تكاليف شحن تسليم مكتب",
              slug: "Costs",
              icon: "HomeIcon",
              i18n: "client_office_costs",
              // isNew: true,
            },
          ]
        },
        {
          header: "المرتجعات",
          icon: "RewindIcon",
          i18n: "returns",
          items: [
            {
              url: "/ReturnWithCompany",
              name: "مرتجع مع الشركة",
              slug: "Return",
              icon: "MailIcon",
              i18n: "withCompany",
            },
            {
              url: "/Return",
              name: "المرتجعة",
              slug: "Return",
              icon: "PackageIcon",
              i18n: "returned",
            },
            {
              url: "/Resent",
              name: "معاد إرسالها",
              slug: "Return",
              icon: "CalendarIcon",
              i18n: "resend",
            },
          ],
        },
        {
          header: "التقارير",
          icon: "FileTextIcon",
          i18n: "reports",
          items: [
            {
              url: "/reports/AllOrders",
              name: "كل الطرود",
              slug: "report",
              icon: "PackageIcon",
              i18n: "allOrders",
            },
            {
              url: "/reports/Missions",
              name: "جميع المهمات",
              slug: "report",
              icon: "ClipboardIcon",
              i18n: "allMissions",
            },
          ],
        },
        {
          header: "إضافة طرد",
          icon: "PackageIcon",
          i18n: "",
          slug: "newOrder",
          items: [
            {
              url: "/addOrder",
              name: "إضافة طرد",
              slug: "chat",
              icon: "PackageIcon",
              i18n: "addOrder",
            },
            {
              url: "/AddOrderWithExcel",
              name: "إضافة طرود بالإكسل",
              slug: "excel",
              icon: "FileIcon",
              i18n: "addExcel",
            },
            {
              url: "/AddMultipleOrdersSmartly",
              name: "إضافة طرود متعددة بشكل ذكى",
              slug: "excel",
              icon: "SmartFileIcon",
              i18n: "",
            },
          ],
        },
        {
          url: "/addMission",
          name: "مهمة جديدة",
          slug: "chat",
          icon: "CalendarIcon",
          i18n: "addMission",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("userData", ["have_stock"]),
  },
  methods: {
    ...mapActions("userData", ["fetchUserData"]),
    hasAccessCompany() {
      if (this.$acl.not.check("isPublicCompany")) {
        return (
          (this.navMenuItems[1].items[5].name = "withCaptain"),
          (this.navMenuItems[1].items[5].i18n = "withCaptain")
        );
      } else {
        return (
          (this.navMenuItems[1].items[5].name = "inProgress"),
          (this.navMenuItems[1].items[5].i18n = "inProgress")
        );
      }
    },
  },
  async mounted() {
    this.hasAccessCompany();
    await this.fetchUserData();
    if (this.have_stock === 1) {
      this.navMenuItems.push({
        url: "/products",
        name: "منتجاتي",
        slug: "products",
        icon: "BriefcaseIcon",
        i18n: "myProducts",
      });
    }
    if (this.hasAccess) {
      this.navMenuItems[5].items.push({
        url: "/reports/attend-employee",
        name: "حضور الموظفين",
        slug: "report",
        icon: "UsersIcon",
        i18n: "",
      });
    }
  },
};
