<template>
  <div
    v-if="canSee"
    @click="trackEventClickGoogle()"
    class="h-nav-menu-item"
    :class="[
      {'h-nav-active-item text-primary font-medium' : activeLink},
      {'disabled-item pointer-events-none' : isDisabled},
      {'addExcel': slug === 'excel'},
    ]" >

      <router-link
        v-if="to"
        exact
        :class="[{'router-link-active': activeLink}, 'nav-link flex items-center']"
        :to="to"
        :target="target" >
          <vs-icon v-if="!featherIcon && icon != 'FaqIcon'" :icon-pack="iconPack" :icon="icon" />
          <svg v-else-if="icon == 'FaqIcon'" xmlns="http://www.w3.org/2000/svg" class="mr-3" width="18" height="18" viewBox="0 0 18 18">
            <path id="Path_1649" data-name="Path 1649" d="M452.2,187.375a9,9,0,1,0,9,9A9,9,0,0,0,452.2,187.375Zm0,16.594a7.594,7.594,0,1,1,7.594-7.594A7.59,7.59,0,0,1,452.2,203.969Zm2.813-9.264a2.817,2.817,0,0,1-2.109,2.723v1.144a.7.7,0,1,1-1.406,0v-1.758a.7.7,0,0,1,.7-.7,1.406,1.406,0,1,0-1.406-1.406.7.7,0,1,1-1.406,0,2.813,2.813,0,0,1,5.625,0Zm-1.933,5.977a.879.879,0,1,1-.879-.879A.879.879,0,0,1,453.083,200.682Z" transform="translate(-443.204 -187.375)" fill="#6e6b7b"/>
          </svg>
          <svg v-else-if="icon == 'SmartFileIcon'" class="mr-3" width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6666 1.89154V5.33366C11.6666 5.80037 11.6666 6.03372 11.7574 6.21198C11.8373 6.36879 11.9648 6.49627 12.1216 6.57616C12.2999 6.66699 12.5332 6.66699 12.9999 6.66699H16.442M16.6666 8.32385V14.3337C16.6666 15.7338 16.6666 16.4339 16.3941 16.9686C16.1544 17.439 15.772 17.8215 15.3016 18.0612C14.7668 18.3337 14.0667 18.3337 12.6666 18.3337H7.33325C5.93312 18.3337 5.23306 18.3337 4.69828 18.0612C4.22787 17.8215 3.84542 17.439 3.60574 16.9686C3.33325 16.4339 3.33325 15.7338 3.33325 14.3337V5.66699C3.33325 4.26686 3.33325 3.5668 3.60574 3.03202C3.84542 2.56161 4.22787 2.17916 4.69828 1.93948C5.23306 1.66699 5.93312 1.66699 7.33325 1.66699H10.0097C10.6212 1.66699 10.9269 1.66699 11.2147 1.73607C11.4698 1.79731 11.7136 1.89832 11.9373 2.03539C12.1896 2.19 12.4058 2.40619 12.8382 2.83857L15.495 5.49542C15.9274 5.9278 16.1436 6.14399 16.2982 6.39628C16.4353 6.61996 16.5363 6.86382 16.5975 7.11891C16.6666 7.40663 16.6666 7.71237 16.6666 8.32385Z" stroke="#898989" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.44114 14L8.48514 9.902H9.93114L10.9691 14H10.1411L9.91314 13.172H8.47914L8.26314 14H7.44114ZM8.61114 12.506H9.79314L9.33114 10.574H9.07914L8.61114 12.506ZM11.421 14V9.902H12.249V14H11.421Z" fill="#898989"/>
          </svg>
          <feather-icon v-else :class="iconClasses" :icon="icon" />
          <slot />

          <span v-if="isNew" class="ml-2 px-2 bg-success text-white rounded-lg">{{ $t('new') }}</span>
      </router-link>

      <a v-else :target="target" :href="href" class="nav-link flex items-center">
        <vs-icon v-if="!featherIcon" :icon-pack="iconPack" :icon="icon" />
        <feather-icon v-else :class="iconClasses" :icon="icon" />
        <slot />

        <span v-if="isNew" class="ml-2 px-2 bg-success text-white rounded-lg">{{ $t('new') }}</span>
      </a>
  </div>
</template>

<script>
import gTag from "@/mixins/gtag.js"

export default {
  name: 'v-nav-menu-item',
  mixins: [gTag],
  props: {
    isNew        : { type: Boolean,                 default: false               },
    icon        : { type: String,                 default: ''               },
    iconSmall   : { type: Boolean,                default: false            },
    iconPack    : { type: String,                 default: 'material-icons' },
    href        : { type: [String, null],         default: '#'              },
    to          : { type: [String, Object, null], default: null             },
    slug        : { type: String,                 default: null             },
    index       : { type: [String, Number],       default: null             },
    featherIcon : { type: Boolean,                default: true             },
    target      : { type: String,                 default: '_self'          },
    isDisabled  : { type: Boolean,                default: false            },
    header      : { type: Object },
    name      : { type: String }
  },
  computed: {
    iconClasses () {
      return this.iconSmall ? 'w-3 h-3 mr-3' : 'w-5 h-5 mr-3'
    },
    canSee () {
      return true
    },
    activeLink () {
      return !!((this.to === this.$route.path || this.$route.meta.parent === this.slug) && this.to)
    }
  },
  methods: {
    trackEventClickGoogle() {
      if (this.to === '/addOrder') {
        this.googleEventTrack('الضغط على زر اضافة طرد', 'navmenu', 'add_new_order_item_nav_menu')
      }else if (this.to === '/AddOrderWithExcel') {
        this.googleEventTrack('الضغط على زر اضافة طرود بالاكسيل', 'navmenu', 'add_new_order_excel_item_nav_menu')
      }else if (this.header && this.header.i18n === 'orders') {
        this.googleEventTrack(`الضغط علي زر ${this.name}`, 'navmenu', 'order_item_nav_menu')
      }
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/horizontalNavMenuItem.scss";
</style>
