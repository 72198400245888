<template>
  <div
    class="skeleton-loading"
    :style="{
      width: width + 'px',
      height: height + 'px',
      borderRadius: borderRadius + 'px',
    }"
  ></div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    borderRadius: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton-loading {
  background-color: #ddd;
  animation: pulse-bg 1s infinite;
  cursor: wait;
}
@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}
</style>
