<template>
  <div class="types" v-if="!submit">
    <p class="ticket-type ticket-type-active">
      {{ title }}
    </p>
    <div class="sub-type-input">
      <!-- {{allTypes.suggestion == type ? 'تفاصيل المقترح' : 'تفاصيل الشكر'}} -->
      <label>التفاصيل</label>
      <!-- <textarea v-model="description" rows="3" cols="40" placeholder="مثال : اكتب التفاصيل"></textarea> -->
      <div
        class="description"
        :style="imageUrl.length ? 'paddingTop: 56px' : ''"
      >
        <div class="inner-description" v-if="imageUrl.length">
          <div
            class="description-img"
            v-for="(url, index) in imageUrl"
            :key="index"
          >
            <svg
              @click="removeImage(index)"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 32.177C7.8521 32.177 0.822937 25.1479 0.822937 16.5C0.822937 7.85204 7.8521 0.822876 16.5 0.822876C25.1479 0.822876 32.1771 7.85204 32.1771 16.5C32.1771 25.1479 25.1479 32.177 16.5 32.177ZM16.5 3.01038C9.06252 3.01038 3.01044 9.06246 3.01044 16.5C3.01044 23.9375 9.06252 29.9895 16.5 29.9895C23.9375 29.9895 29.9896 23.9375 29.9896 16.5C29.9896 9.06246 23.9375 3.01038 16.5 3.01038Z"
                fill="#3e4042"
              />
              <path
                d="M12.3729 21.7207C12.0959 21.7207 11.8188 21.6187 11.6 21.3999C11.1771 20.977 11.1771 20.277 11.6 19.8541L19.8542 11.5999C20.2771 11.177 20.9771 11.177 21.4 11.5999C21.8229 12.0228 21.8229 12.7228 21.4 13.1457L13.1459 21.3999C12.9417 21.6187 12.65 21.7207 12.3729 21.7207Z"
                fill="#fff"
              />
              <path
                d="M20.6271 21.7207C20.35 21.7207 20.0729 21.6187 19.8542 21.3999L11.6 13.1457C11.1771 12.7228 11.1771 12.0228 11.6 11.5999C12.0229 11.177 12.7229 11.177 13.1459 11.5999L21.4 19.8541C21.8229 20.277 21.8229 20.977 21.4 21.3999C21.1813 21.6187 20.9042 21.7207 20.6271 21.7207Z"
                fill="#fff"
              />
            </svg>
            <img :src="url" class="display-upload-img" alt="Image" />
          </div>
        </div>
        <span
          role="textbox"
          contenteditable
          ref="description"
          @input="getDescription"
        ></span>
      </div>
      <div class="upload-img" v-if="canUpload && allTypes.suggestion == type">
        <button class="text-center">
          <img
            src="@/assets/images/elements/upload img.svg"
            alt="Upload image"
          />
          <h6>قم برفع صورة</h6>
        </button>
        <span>برجاء إدخال صورة للتوضيح ( إن وجد )</span>
        <input
          @input="previewFiles"
          title=""
          accept="image/*"
          type="file"
          multiple
        />
      </div>
    </div>
    <button
      class="ticket-btn-send"
      :class="{ 'ticket-btn-disabled': !validation }"
      :disabled="!validation"
      @click="createNewTicket()"
      v-if="!loading"
    >
      إرسال
    </button>
    <button v-else class="ticket-btn-send"><SpinnerColor /></button>
  </div>
  <div v-else class="types ">
    <div class="handle-error-ticket handle-lottie-success flex flex-col gap-24 items-center" v-if="!error">
      <lottie-animation
        ref="anim"
        :animationData="require('@/layouts/components/support-ticket/lottie-go.json')"
        :loop="true"
        :speed="1"
      />
      <h6 class="warn-ticket-message mt-0">
        {{
          allTypes.suggestion == type
            ? `تم إرسال رسالتك بنجاح سعداء بخدمة حضرتك في تربو`
            : `تم إرسال رسالتك بنجاح سعداء بخدمة حضرتك في تربو`
        }}
      </h6>
      <button class="start-new-ticket" @click="newTicket()">
        الصفحة الرئيسية
      </button>
    </div>
    <div class="handle-error-ticket" v-else>
      <lottie-animation
        ref="anim"
        :animationData="require('@/layouts/components/support-ticket/lottie-error.json')"
        :loop="true"
        :speed="1"
      />
      <h6 class="warn-ticket-message">
        عفواً، حدث خطاء ما برجاء المحاولة مرة اخري
      </h6>
      <button class="start-new-ticket" @click="newTicket()">تذكرة جديدة</button>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import { mapActions, mapGetters } from "vuex";
import ticketStatus from "@/views/status/ticketStatus";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";

export default {
  data() {
    return {
      allTypes: ticketStatus.types,
      description: "",
      submit: false,
      error: false,
      imageUrl: [],
      imagesSrc: [],
      count: 0,
      loading: false
    };
  },
  components: {
    LottieAnimation,
    SpinnerColor,
  },
  computed: {
    ...mapGetters("ticket", ["success", "error_msg"]),
    validation() {
      if (this.description != "" && this.description.length >= 10) {
        return true;
      } else {
        return false;
      }
    },
    canUpload() {
      if (this.imageUrl.length < 3) {
        return true;
      } else {
        return false;
      }
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions("ticket", ["createTicket", "newTicket"]),
    async createNewTicket() {
      if (this.validation) {
        this.loading = true;
        let obj = {
          entityId: null,
          description: this.description,
          typeTicket: this.type,
          complainTypeId: null,
          complainTypeTitleId: null,
          inquiryTypeId: null,
          image: this.imagesSrc,
        };
        await this.createTicket(obj);
        this.loading = false;
        if (this.success == 1) {
          this.submit = true;
          this.error = false;
        } else {
          this.submit = true;
          this.error = true;
        }
      }
    },
    async previewFiles(e) {
      if (e.target.files[0]) {
        for (var i = 0; i < e.target.files.length; i++) {
          var reader = new FileReader();
          if (this.imagesSrc.length < 3) {
            this.imagesSrc.push(e.target.files[i]);
            reader.onload = (e) => {
              this.imageUrl.push(e.target.result);
            };
            reader.readAsDataURL(e.target.files[i]);
          }
        }
      }
    },
    removeImage(index) {
      this.imageUrl.splice(index, 1);
      this.imagesSrc.splice(index, 1);
    },
    getDescription(e) {
      this.description = e.target.innerText;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_supportTicket.scss";
</style>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
.handle-lottie-success {
  svg {
    g {
      g {
        g {
          &:nth-last-child(1) {
            path {
              fill: #fff;
            }
          }
          &:nth-last-child(2) {
            path {
              fill: #fff;
            }
          }
          &:nth-last-child(3) {
            path {
              fill: $mainColor1;
            }
          }
          &:nth-last-child(4) {
            path {
              fill: $mainColor1;
            }
          }
          &:nth-last-child(5) {
            path {
              fill: $mainColor1;
            }
          }
        }
      }
    }
  }
}
</style>
