<template>
    <div>
        <add-advanced-search :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" />
        <i data-tour-step="3" class="mt-4 cursor-pointer" style="border-radius: 4px; padding: 7px 6px; padding-bottom: 0px;" @click="googleEventTrack('الضغط على زر البحث المتقدم', 'navbar', 'advanced_search_icon'), addDataSidebar()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20.41" height="20.239" viewBox="0 0 20.41 20.239">
                <g id="Group_655" data-name="Group 655" transform="translate(-295 -19)">
                <path id="Union_1" data-name="Union 1" d="M-3750.021-3897.2l4.432,4.443Zm-13.979-6.11a7.675,7.675,0,0,1,7.665-7.686,7.675,7.675,0,0,1,7.666,7.686,7.675,7.675,0,0,1-7.666,7.686A7.675,7.675,0,0,1-3764-3903.314Z" transform="translate(4060 3931)" fill="none" stroke="#6e6b7b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M6.3,4.913H4.116V2.734a.484.484,0,0,0-.484-.484H3.148a.484.484,0,0,0-.484.484V4.913H.484A.484.484,0,0,0,0,5.4v.484a.484.484,0,0,0,.484.484H2.663V8.545a.484.484,0,0,0,.484.484h.484a.484.484,0,0,0,.484-.484V6.366H6.3a.484.484,0,0,0,.484-.484V5.4A.484.484,0,0,0,6.3,4.913Z" transform="translate(300 21.971)" fill="#6e6b7b"/>
                </g>
            </svg>
        </i>
    </div>
</template>

<script>
import AddAdvancedSearch from '@/views/orders/AddAdvancedSearch.vue'
import gTag from "@/mixins/gtag.js"

export default {
    mixins: [gTag],
    data(){
        return{
            addNewDataSidebar: false,
        }
    },
    components: {
        AddAdvancedSearch,
    },
    methods: {
        toggleDataSidebar (val = false) {
            this.addNewDataSidebar = val
        },
        addDataSidebar () {
            this.toggleDataSidebar(true)
        }
    },
    mounted () {
    }
}
</script>