<template>
  <div>
    <vs-sidebar
      click-not-close
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="add-new-data-sidebar items-no-padding"
      spacer
      v-model="isSidebarActiveLocal"
    >
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ $t("helpCenter") }}</h4>
        <feather-icon
          icon="XIcon"
          @click.stop="isSidebarActiveLocal = false"
          class="cursor-pointer"
        ></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>

      <component
        :is="scrollbarTag"
        class="scroll-area--data-list-add-new"
        :settings="settings"
        :key="$vs.rtl"
      >
        <div class="p-6" v-if="!loading">
          <div class="input-search-help">
            <input
              id="input-search-help"
              v-model="search"
              @input="videoResult()"
              @blur="hideResult()"
              @click="showResult()"
              placeholder="إبحث هنا عن ما تريد"
            />
            <i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.414"
                height="16.653"
                viewBox="0 0 16.414 16.653"
              >
                <g id="search" transform="translate(1 1)">
                  <circle
                    id="Ellipse_274"
                    data-name="Ellipse 274"
                    cx="6"
                    cy="6"
                    r="6"
                    fill="none"
                    stroke="#6e6b7b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                  <line
                    id="Line_69"
                    data-name="Line 69"
                    x1="3.469"
                    y1="3.469"
                    transform="translate(10.531 10.77)"
                    fill="none"
                    stroke="#6e6b7b"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </g>
              </svg>
            </i>
            <div class="suggest-search">
              <div
                class="the-result"
                v-if="search != ''"
                v-for="(result, index) in resultOfSearch"
                :key="index"
                @click="check(result)"
                :title="result.title"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.414"
                    height="16.653"
                    viewBox="0 0 16.414 16.653"
                  >
                    <g id="search" transform="translate(1 1)">
                      <circle
                        id="Ellipse_274"
                        data-name="Ellipse 274"
                        cx="6"
                        cy="6"
                        r="6"
                        fill="none"
                        stroke="#6e6b7b"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                      <line
                        id="Line_69"
                        data-name="Line 69"
                        x1="3.469"
                        y1="3.469"
                        transform="translate(10.531 10.77)"
                        fill="none"
                        stroke="#6e6b7b"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </svg>
                </span>
                <div>
                  <h6
                    style="
                      text-overflow: ellipsis;
                      overflow: hidden;
                      width: 205px;
                      white-space: nowrap;
                    "
                  >
                    {{ result.title }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="video-help-center" v-if="videos.length != 0">
            <h6>فيديوهات شرح السيستم :</h6>
            <div
              class="article flex items-center"
              @click="goToHelpCenter(video.url)"
              v-for="(video, index) in lastVideos"
              :key="index"
            >
              <div class="img-article">
                <img :src="video.thumbnail" />
                <div class="overlay"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="25"
                  height="29"
                  viewBox="0 0 67.391 74.45"
                >
                  <defs>
                    <filter
                      id="Icon_awesome-play"
                      x="0"
                      y="0"
                      width="67.391"
                      height="74.45"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" input="SourceAlpha" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood flood-opacity="0.102" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    transform="matrix(1, 0, 0, 1, 0, 0)"
                    filter="url(#Icon_awesome-play)"
                  >
                    <path
                      id="Icon_awesome-play-2"
                      data-name="Icon awesome-play"
                      d="M46.79,23.669,7.982.726A5.263,5.263,0,0,0,0,5.28V51.155a5.287,5.287,0,0,0,7.982,4.553L46.79,32.776A5.287,5.287,0,0,0,46.79,23.669Z"
                      transform="translate(9 6)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </div>
              <div class="article-content">
                <h5>{{ video.title }}</h5>
                <p v-html="video.description"></p>
              </div>
            </div>
            <a
              @click="
                $router.push('/faq/helpcenter').catch(() => {}),
                  (isSidebarActiveLocal = false)
              "
              class="more-video"
              >المزيد</a
            >
          </div>

          <div class="common-article">
            <h6>أسئلة شائعة :</h6>
            <p
              v-for="(last, index) in lastFaq"
              :key="index"
              @click="check(last)"
            >
              <a>{{ last.question }}</a>
            </p>
          </div>
        </div>
        <div
          class="flex justify-center items-center"
          style="height: 100%"
          v-else
        >
          <SpinnerColor />
        </div>
      </component>

      <div
        class="flex flex-wrap items-center px-6 py-4 justify-between footer"
        slot="footer"
        v-if="!loading"
      >
        <a
          @click="
            $router.push('/faq/faq').catch(() => {}),
              (isSidebarActiveLocal = false)
          "
          >الأسئلة الشائعة</a
        >
        <a
          @click="
            $router.push('/termsAndConditions').catch(() => {}),
              (isSidebarActiveLocal = false)
          "
          >الشروط والأحكام</a
        >
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters } from "vuex";
import SpinnerColor from "../../../../views/components/table-components/SpinnerColor2.vue";
import $ from "jquery";

export default {
  name: "sidebar-help-center",
  props: {
    isSidebarActive: {
      type: Boolean,
    },
  },
  components: {
    VuePerfectScrollbar,
    SpinnerColor,
  },
  data() {
    return {
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      search: "",
      resultOfSearch: "",
      mergedArray: [],
      lastFaq: [],
      lastVideos: [],
      loading: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters("dataList", ["faq", "videos"]),
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    ...mapActions("dataList", ["fetchFaq", "fetchVideos"]),
    goToHelpCenter(vidId) {
      this.$router
        .push({
          path: `/faq/helpcenter`,
          query: {
            vidId: vidId,
          },
        })
        .catch(() => {});
      this.isSidebarActiveLocal = false;
    },
    hideResult() {
      $(".suggest-search").slideUp();
    },
    showResult() {
      $(".suggest-search").slideDown();
    },
    videoResult() {
      this.resultOfSearch = this.mergedArray.filter((mergedArray) =>
        mergedArray.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    check(result) {
      if (!result.url) {
        this.$router
          .push({ path: `/faq/faq`, query: { id: result.id } })
          .catch(() => {});
      } else {
        this.goToHelpCenter(result.url);
      }
      this.isSidebarActiveLocal = false;
    },
  },
  async created() {
    this.loading = true;
    await this.fetchVideos();
    await this.fetchFaq();
    this.lastFaq = this.faq.slice(0, 3);
    this.lastVideos = this.videos.slice(0, 4);
    this.mergedArray = this.faq.concat(this.videos);
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
@import "@/assets/scss/vuexy/_suggestSearch.scss";
@import "@/assets/scss/vuexy/_advancedSearch.scss";

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 1rem - 2.8125rem - 5.125rem);

  &:not(.ps) {
    overflow-y: auto;
  }
}

.input-search-help {
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #b9b9c3;
    background-color: #fff;
    color: #212121;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 21px;
    &::placeholder {
      color: #6e6b7b;
      opacity: 50%;
    }
  }
  i {
    position: absolute;
    top: 10px;
    right: 12px;
  }
}
.video-help-center {
  h6 {
    color: #6e6b7b;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  margin-bottom: 12px;
  .article {
    margin-bottom: 25px;
    cursor: pointer;
    .img-article {
      width: 55px;
      height: 55px;
      position: relative;
      margin-right: 12px;
      img {
        margin-right: 12px;
        border-radius: 8px;
        width: 55px;
        height: 100%;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
    .overlay {
      border-radius: 8px;
      position: absolute;
      opacity: 60%;
      background-image: linear-gradient(180deg, #31006f41, #31006f);
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      mix-blend-mode: multiply;
    }
    .article-content {
      h5 {
        color: #212121;
        font-size: 14px;
        line-height: 21px;
        font-weight: bold;
      }
      P {
        font-size: 12px;
        line-height: 20px;
        color: #6e6b7b;
      }
    }
  }
  .more-video {
    text-decoration: underline;
    font-size: 12px;
    line-height: 18px;
    color: #6e6b7b;
    display: block;
    text-align: right;
    cursor: pointer;
  }
}
.common-article {
  h6 {
    font-size: 12px;
    line-height: 18px;
    color: #6e6b7b;
    font-weight: 600;
    margin-bottom: 12px;
  }
  P {
    margin-bottom: 12px;
    a {
      color: $mainColor1;
      font-weight: 600;
      font-size: 13px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  // margin-bottom: 36px;
}
.footer {
  background-color: #f0f0f0;
  a {
    color: #6e6b7b;
    font-size: 12px;
    line-height: 18px;
    text-decoration: none;
    cursor: pointer;
  }
}
.suggest-search {
  top: 45px;
}
</style>
