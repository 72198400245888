<template>
    <div v-if="error" class="handle-error-ticket">
        <lottie-animation
            v-if="!afterFiveOclock"
            ref="anim"
            :animationData="require('./lottie-error.json')"
            :loop="true"
            :speed="1"
        />
        <lottie-animation
            v-else
            ref="anim"
            :animationData="require('./lottie-go.json')"
            :loop="true"
            :speed="1"
        />
        <h6 class="warn-ticket-message">{{afterFiveOclock ? `تم إرسال رسالتك بنجاح
        سيتم الرد عليها في اقرب وقت` : `عفواً، حدث خطاء ما
        برجاء المحاولة مرة اخري`}}</h6>
        <button class="start-new-ticket" @click="newTicket()">محادثة جديدة</button>
    </div>
    <div v-else>
        <p class="ticket-type ticket-type-active">
            {{dataCreatTicket.subTitle}}
        </p>
        <div v-if="dataCreatTicket.typeTicket == 2 && dataCreatTicket.relatedModel != 3">
            <div class="sub-type-input" v-if="(dataCreatTicket.relatedModel == 0 || dataCreatTicket.relatedModel == 4)">
                <label>{{ dataCreatTicket.relatedModel == 0 ? "كود الطرد" : "كود المهمة" }} </label>
                <input v-model="entityId" placeholder="مثال: 330" />
                <span class="text-danger err-msg" v-if="error_msg">{{error_msg}} <span class="text-primary font-bold cursor-pointer" @click="toChat()"> <u v-if="live_ticket.id">{{live_ticket.id}}</u> </span></span>
            </div>
            <!-- problem here -->
            <div class="sub-type-input" v-else-if="dataCreatTicket.relatedModel == 2">
                <label>اسم الكابتن</label>
                <div class="search-input">
                    <SelectInputVue
                        :options="allCaptains"
                        v-model="entityId"
                        v-validate="'required'"
                        name="branch"
                        class="mb-3"
                    >
                        <template #search>
                            <input
                                @blur.stop
                                class="inner-input"
                                v-model="searchCaptain"
                                @input="searchAll(2)"
                                placeholder="ابحث هنا باسم الكابتن"
                            />
                        </template>
                        <template #loading>
                            <div class="search-loading" v-if="loading">
                                <SpinnerColor />
                            </div>
                        </template>
                    </SelectInputVue>
                    <span class="text-danger font-bold err-msg" v-if="error_msg">{{error_msg}} <span class="text-primary font-bold cursor-pointer" @click="toChat()"> <u v-if="live_ticket.id">{{live_ticket.id}}</u> </span></span>
                </div>
            </div>
            <!-- <div class="sub-type-input" v-else-if="dataCreatTicket.relatedModel == 2">
                <label>اسم الكابتن</label>
                <div class="search-input">
                    <vs-select v-model="entityId" name="captain" placeholder="مثال : حمزة">
                        <input v-model="searchCaptain" @input="searchAll(2)" placeholder="إبحث هنا" />
                        <div class="search-loading" v-if="loading">
                            <SpinnerColor />
                        </div>
                        <div v-else>
                            <vs-select-item :key="captain.id" :value="captain.captain_id" :text="captain.name" v-for="captain in captains" />
                        </div>
                    </vs-select> 
                </div>
            </div>-->

        
            <div class="sub-type-input" v-else-if="dataCreatTicket.relatedModel == 1">
                <label>اسم الفرع</label>
                <div class="search-input">
                    <SelectInputVue
                        :options="allBranches"
                        v-model="entityId"
                        v-validate="'required'"
                        name="branch"
                        class="mb-3"
                    >
                        <template #search>
                            <input
                                @blur.stop
                                class="inner-input"
                                v-model="searchBranch"
                                @input="searchAll(1)"
                                placeholder="ابحث هنا باسم الفرع"
                            />
                        </template>
                        <template #loading>
                            <div class="search-loading" v-if="loading">
                                <SpinnerColor />
                            </div>
                        </template>
                    </SelectInputVue>
                    <span style="font-size: 0.7rem" class="text-danger font-bold err-msg">{{
                        errors.first("branch")
                    }} </span>
                    <span class="text-danger err-msg" v-if="error_msg"> - {{error_msg}} <span class="text-primary font-bold cursor-pointer" @click="toChat()"> <u v-if="live_ticket.id">{{live_ticket.id}}</u> </span></span>
                </div>
            </div>
        </div>
        <div class="sub-type-input">
            <label>{{ dataCreatTicket.typeTicket == 1 ? 'تفاصيل الاستفسار' : $t('detailsOfProblem') }}</label>
            <div class="description" :style="imageUrl.length ? 'paddingTop: 56px' : ''">
                <div class="inner-description" v-if="imageUrl.length" >
                    <div class="description-img" v-for="(url, index) in imageUrl" :key="index">
                        <svg @click="removeImage(index)" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5 32.177C7.8521 32.177 0.822937 25.1479 0.822937 16.5C0.822937 7.85204 7.8521 0.822876 16.5 0.822876C25.1479 0.822876 32.1771 7.85204 32.1771 16.5C32.1771 25.1479 25.1479 32.177 16.5 32.177ZM16.5 3.01038C9.06252 3.01038 3.01044 9.06246 3.01044 16.5C3.01044 23.9375 9.06252 29.9895 16.5 29.9895C23.9375 29.9895 29.9896 23.9375 29.9896 16.5C29.9896 9.06246 23.9375 3.01038 16.5 3.01038Z" fill="#3e4042"/>
                            <path d="M12.3729 21.7207C12.0959 21.7207 11.8188 21.6187 11.6 21.3999C11.1771 20.977 11.1771 20.277 11.6 19.8541L19.8542 11.5999C20.2771 11.177 20.9771 11.177 21.4 11.5999C21.8229 12.0228 21.8229 12.7228 21.4 13.1457L13.1459 21.3999C12.9417 21.6187 12.65 21.7207 12.3729 21.7207Z" fill="#fff"/>
                            <path d="M20.6271 21.7207C20.35 21.7207 20.0729 21.6187 19.8542 21.3999L11.6 13.1457C11.1771 12.7228 11.1771 12.0228 11.6 11.5999C12.0229 11.177 12.7229 11.177 13.1459 11.5999L21.4 19.8541C21.8229 20.277 21.8229 20.977 21.4 21.3999C21.1813 21.6187 20.9042 21.7207 20.6271 21.7207Z" fill="#fff"/>
                        </svg>
                        <img :src="url" class="display-upload-img" alt="Image" />
                    </div>
                </div>
                <span role="textbox" contenteditable ref="description" @input="getDescription"></span>
            </div>
            <div class="upload-img" v-if="canUpload">
                <button class="text-center">
                    <img src="@/assets/images/elements/upload img.svg" alt="Upload image" />
                    <h6>قم برفع صورة</h6>
                </button>
                <span>يجب اختيار 3 صور بحد اقصي</span>
                <input @input="previewFiles" title="" ref="uploadImg" accept="image/*" type="file" multiple>
            </div>
        </div>
        <button v-if="!loadingSubmit" class="ticket-btn-send" :class="{'ticket-btn-disabled': !validation}" :disabled="!validation" @click="createNewTicket()">إبدأ المحادثة</button>
        <button v-else-if="loadingSubmit" class="ticket-btn-send"><SpinnerColor /></button>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LottieAnimation from 'lottie-web-vue'
import SpinnerColor from '@/views/components/table-components/SpinnerColor2.vue'
import SelectInputVue from '../SelectInput.vue'

export default {
    data(){
        return {
            entityId: '',
            description: '',
            captainId: '',
            searchCaptain: '',
            // captains: '',
            searchBranch: '',
            // branches: '',
            error: false,
            afterFiveOclock: false,
            submit: false,
            pageForBranches: 1,
            pageForCaptains: 1,
            loading: false,
            loadingSubmit: false,
            imageUrl: [],
            rawFiles: '',
            imagesSrc: [],
            allBranches: [],
            allCaptains: [],
        }
    },
    props: {
        dataCreatTicket: {
            type: Object,
            required: true
        }
    },
    components: {
        LottieAnimation,
        SpinnerColor,
        SelectInputVue
    },
    computed: {
        ...mapGetters('dataList', ['branches', 'captains']),
        ...mapGetters('ticket', ['success', 'error_msg', 'live_ticket']),
        validation(){
            if(this.dataCreatTicket.typeTicket == 2 && (this.dataCreatTicket.relatedModel != 3 && this.dataCreatTicket.relatedModel != null)){
                if(this.entityId != '' && this.description != '' && this.description.length >= 10){
                    return true
                }else{
                    return false
                }
            }
            else{
                if(this.description != '' && this.description.length >= 10){
                    return true
                }else{
                    return false
                }
            }
        },
        canUpload(){
            if(this.imageUrl.length < 3){
                return true
            }else {
                return false
            }
        }
    },
    methods: {
        ...mapActions('ticket', ['createTicket', 'nextPage', 'newTicket', 'toggleShowTicket', 'setEntityId']),
        ...mapActions('dataList', ['getBranches', 'getCaptains']),
        async createNewTicket() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    if(this.validation){
                        let obj = {
                            entityId: this.entityId,
                            description: this.description,
                            typeTicket: this.dataCreatTicket.typeTicket,
                            complainTypeId: this.dataCreatTicket.complainTypeId,
                            complainTypeTitleId: this.dataCreatTicket.complainTypeTitleId,
                            inquiryTypeId: this.dataCreatTicket.inquiryTypeId,
                            image: this.imagesSrc
                        }
                        if(this.dataCreatTicket.entityId) obj["entityId"] = this.dataCreatTicket.entityId;
                        this.loadingSubmit = true
                       await this.createTicket(obj)
                        if(this.success == 1){
                            this.setEntityId(false)
                            this.checkTime()
                        }
                        this.loadingSubmit = false
                    }
                }
            })
        },
        checkTime(){
            const date = new Date();
            const time = date.getHours();
            if(time >= 17 || time < 9){
                this.error = true
                this.afterFiveOclock = true
            }else{
                this.$emit("stopLoading")
                this.nextPage()
            }
        },
        async searchAll(type){
            this.allBranches = []
            this.loading = true
            if(type == 1 && this.searchBranch != ''){
                let obj = {
                    page: this.pageForBranches,
                    search: this.searchBranch
                }
                await this.getBranches(obj)
                this.allBranches = this.branches
            }
            else if(type == 2 && this.searchCaptain != ''){
                let obj = {
                    page: this.pageForCaptains,
                    search: this.searchCaptain
                }
                await this.getCaptains(obj)         
                this.allCaptains = this.captains
            }
            this.loading = false
        },
        async previewFiles(e){
            if (e.target.files[0] && !this.loadingSubmit) {
                for (var i = 0; i < e.target.files.length; i++) {
                    var reader = new FileReader();
                    if (this.imagesSrc.length < 3) {
                        this.imagesSrc.push(e.target.files[i])
                        reader.onload = (e) => {
                            this.imageUrl.push(e.target.result)
                        };
                        reader.readAsDataURL(e.target.files[i]);
                    }
                }
            }
        },
        removeImage(index) {
            if(!this.loadingSubmit){
                if(this.$refs.uploadImg)
                    this.$refs.uploadImg.value = ""
                this.imageUrl.splice(index, 1)
                this.imagesSrc.splice(index, 1)
            }
        },
        getDescription(e){
            if(!this.loadingSubmit){
                this.description = e.target.innerText
            }
        },
        toChat(){
            this.$router.push({ path: '/apps/chat/'+this.live_ticket.id, query: { type: 1 }})
            this.newTicket()
            this.toggleShowTicket()
        }
    },
    mounted (){
        if(this.dataCreatTicket.entityId) {
            this.entityId = this.dataCreatTicket.entityId
        }
    }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vuexy/_supportTicket.scss';
</style>