<template>
  <div class="flex">
    <div class="search-full-container w-full h-full absolute left-0 top-0" :class="{'flex': showFullSearch}" v-if="showFullSearch">
    
      <input
        ref="navbarSearch"
        search_key="title"
        background-overlay
        class="w-full input-search"
        icon="SearchIcon"
        placeholder="ادخل الكود للبحث عن طردك"
        v-model="searchQuery"
        @keyup.esc="showFullSearch = false"
        @keyup.enter="searchCode()"
        @blur="showFullSearch = false, searchQuery = ''" />

      <div class="absolute right-0 h-full z-50">
        <feather-icon
          icon="XIcon"
          class="px-4 cursor-pointer h-full close-search-icon"
          @click="showFullSearch = false" />
      </div>
      <p class="error-code" v-if="error">برجاء ادخال كود الطرد اولاً</p>
    </div>
    <vx-tooltip text="البحث" position="bottom" delay=".2s">
      <feather-icon svgClasses="'h-6 w-6" class="p-2 cursor-pointer" icon="SearchIcon" @click="showSearch(), googleEventTrack('الضغط على ايقون البحث العادية', 'navbar', 'search_icon_in_navbar')" />
    </vx-tooltip>
  </div>
</template>

<script>
import gTag from "@/mixins/gtag.js"

export default {
  mixins: [gTag],
  components: {
  },
  data () {
    return {
      showFullSearch: false,
      searchQuery: '',
      error: false,
    }
  },
  computed: {

  },
  methods: {
    showSearch(){
      this.showFullSearch = true
      setTimeout(()=>{ this.$refs.navbarSearch.focus(); }, 10);
    },
    searchCode() {
      if(this.searchQuery != '') {
        this.error = false
        this.$router.replace(`/OrderDetails/${this.searchQuery}`).catch(() => {})
      }else {
        this.error = true
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.input-search{
  border: none;
  padding: 0 40px;
  color: #6e6b7b;
  font-family: 'cairo';
  font-size: 16px;
  letter-spacing: 1px;
}
.input-search::placeholder{
  font-family: 'cairo';
  font-size: 16px;
  letter-spacing: 1px;
}
.error-code {
  position: absolute;
  top: 42px;
  font-size: 12px;
  color: red;
  left: 39px;
  background: #ff000017;
  padding: 0px 10px;
  border-radius: 4px;
}
</style>