<template>
  <div class="modal">
    <i class="close-icon" @click="closeModal">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path
          id="Icon_ionic-md-close"
          data-name="Icon ionic-md-close"
          d="M23.523,9.123l-1.6-1.6-6.4,6.4-6.4-6.4-1.6,1.6,6.4,6.4-6.4,6.4,1.6,1.6,6.4-6.4,6.4,6.4,1.6-1.6-6.4-6.4Z"
          transform="translate(-7.523 -7.523)"
          fill="#212121"
        />
      </svg>
    </i>
    <slot name="content"></slot>
    <div class="buttons">
      <button class="btn-logout"><slot name="first-btn"></slot></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalRate",
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
  mounted() {
    document.body.style.pointerEvents = "none";
  },
  destroyed() {
    document.body.style.pointerEvents = "all";
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
@import "@/assets/scss/vuexy/_rate.scss";
body {
  pointer-events: none;
}
.modal {
  pointer-events: all;
  outline: 9999px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 999999;
  background-color: #fff;
  right: 50%;
  transform: translate(50%, -50%);
  top: 50%;
  padding: 24px 50px;
  border-radius: 24px;
  box-shadow: 0px 8px 21px #0000001a;
  svg {
    margin-bottom: 12px;
  }
  h4 {
    font-size: 24px;
    line-height: 28px;
    color: $mainColor1;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .input {
    h6 {
      color: $mainColor1;
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 8px;
    }
    .rate {
      padding: 9px;
      margin-bottom: 16px;
      border-radius: 4px;
      gap: 6px;
      .single-ticket {
        margin: unset;
      }
    }
    input, textarea {
      width: 360px;
      border: 0.0625rem solid #d8d6de;
      border-radius: 0.3125rem;
      height: 2.4375rem;
      padding: 0 0.75rem;
      margin-bottom: 1.5rem;
      font-family: "Cairo";
      font-size: 0.75rem;
      margin-bottom: 24px;
      resize: none;
      color: #212121;
      font-weight: bold;
      &:focus {
        border-color: $mainColor1;
      }
    }
    textarea {
      padding: 0.75rem;
      height: 85px;
    }
  }
  p {
    color: #212121;
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 12px;
    font-weight: bold;
    text-align: center;
  }
  .buttons {
    column-gap: 12px;
    display: flex;
    width: 100%;
    .loader {
      border: 2px solid #fff;
      border-radius: 50%;
      border-top: 2px solid $mainColor1;
    }
  }
  button {
    border: none;
    background-color: $mainColor2;
    padding: 6px 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
    height: 38px;
    cursor: pointer;
    color: $mainColor1;
  }
  .btn-logout {
    width: 100%;
    background-color: $mainColor1;
    color: #fff;
  }
  .third-btn {
    background-color: #fff;
    border: solid 1px $mainColor1;
  }
  .close-icon {
    width: 100%;
    text-align: right;
    margin-left: 48px;
    cursor: pointer;
    height: 16px;
  }
}
</style>
