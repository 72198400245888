<template>
<div>
    <div class="w-full mb-4 bg-white p-4">
      <div class="alert-mobile">
        <div class="install-app">
            <svg xmlns="http://www.w3.org/2000/svg" width="8.042" height="8.04" viewBox="0 0 8.042 8.04" @click="alertMobile()">
                <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M16.259,15.309l2.872-2.872a.673.673,0,1,0-.952-.952l-2.872,2.872-2.872-2.872a.673.673,0,1,0-.952.952l2.872,2.872-2.872,2.872a.673.673,0,0,0,.952.952l2.872-2.872,2.872,2.872a.673.673,0,1,0,.952-.952Z" transform="translate(-11.285 -11.289)" fill="#31006f"/>
            </svg>
            <button><a :href="installApp">Install</a></button>
        </div>
        <div class="logo-app">
            <div>
                <h6>{{ companyName }}</h6>
                <div class="star-rate">
                    <span>(280)</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9.461" height="9.047" viewBox="0 0 9.461 9.047">
                        <path id="Icon_feather-star" data-name="Icon feather-star" d="M7.231,3,8.538,5.648l2.923.427L9.346,8.136l.5,2.911L7.231,9.672,4.616,11.047l.5-2.911L3,6.076l2.923-.427Z" transform="translate(-2.5 -2.5)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                    </svg>
                    <svg :key="star" v-for="star in 4" xmlns="http://www.w3.org/2000/svg" width="8.965" height="8.526" viewBox="0 0 8.965 8.526">
                        <path id="Icon_feather-star" data-name="Icon feather-star" d="M7.482,3,8.868,5.806l3.1.453L9.724,8.442l.529,3.084-2.77-1.457-2.77,1.457.529-3.084L3,6.259l3.1-.453Z" transform="translate(-3 -3)" fill="#31006f"/>
                    </svg>
                </div>
            </div>
            <img :src="iconLogo" class="ml-4" alt="logo" width="41">
        </div>
      </div>
    </div>
</div>
</template>

<script>

export default {
    data(){
        return {
            companyName: process.env.VUE_APP_CONFIGS_COMPANY_NAME,
            iconLogo: process.env.VUE_APP_CONFIGS_CHAT_ICON,
            installApp: process.env.VUE_APP_CONFIGS_INSTALL_APP
        }
    },
    methods: {
        alertMobile(){
            this.$emit('closeAlertMobile')
        }
    }
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';

.alert-mobile{
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: 'transparent linear-gradient(271deg, #31006F 0%, #633998 100%) 0% 0% no-repeat padding-box'
    background-image: linear-gradient(260deg, #31006F33, #19003819);
    border-radius: 6px;
    .logo-app{
        display: flex;
        align-items: center;
        h6{
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
            color: $mainColor1;
            text-align: right;
        }
        .star-rate{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            span{
                color: #6E6B7B;
                font-size: 14px;
                font-weight: normal;
            }
            svg{
                margin-left: 2px;
            }
        }
    }
    .install-app{
        svg{
            cursor: pointer;
        }
        button{
            background-color: $mainColor1;
            border-radius: 4px;
            width: 67px;
            border: none;
            margin-left: 12px;
            a{
                color: #FFF;
                font-size: 14px;
                font-weight: normal;
            }
            
        }
    }
}
</style>