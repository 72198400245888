<template>
    <img :src="image" />
</template>

<script>
export default {
  data() {
    return {
      image: process.env.VUE_APP_CONFIGS_MAIN_LOGO,
    };
  },
};
</script>
