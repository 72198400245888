const types = {
  inquiry: 1,
  complaint: 2,
  gratitude: 3,
  suggestion: 4,
  connectWithClient: 5,
  request: 6,
};
const status = [
  { name: "شكوي جديدة", type: types.complaint },
  { name: "استفسار جديد", type: types.inquiry },
  { name: "مقترح جديد", type: types.suggestion },
  { name: "شكر", type: types.gratitude },
];
export default {
  types,
  status,
};
