export default {
    data(){
        return{
            imageFormats: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'],
            audioFomrats: ['mp4']
        }
    },
    methods: {
        checkImageFormat(urlFile){
            if(urlFile){
                let format = urlFile.split('.').pop()
                if(this.imageFormats.includes(format)){
                    return true
                }else{
                    return false
                }
            }
        },
        checkAudio(urlFile){
            if(urlFile){
                let format = urlFile.split('.').pop()
                if(this.audioFomrats.includes(format)){
                    return true
                }else{
                    return false
                }
            }
        }
    },
    created(){
        
    }
}