<template>
  <div class="the-navbar__user-meta flex items-center">
    <div class="overlay-modal-logout" v-show="openModalLogout" @click="openModalLogout = false"></div>
    <div class="modal-logout" v-show="openModalLogout">
      <i class="close-icon" @click="openModalLogout = false">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <path id="Icon_ionic-md-close" data-name="Icon ionic-md-close" d="M23.523,9.123l-1.6-1.6-6.4,6.4-6.4-6.4-1.6,1.6,6.4,6.4-6.4,6.4,1.6,1.6,6.4-6.4,6.4,6.4,1.6-1.6-6.4-6.4Z" transform="translate(-7.523 -7.523)" fill="#212121"/>
        </svg>
      </i>
      <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
        <g id="Group_2175" data-name="Group 2175" transform="translate(-672 -363)">
          <circle id="Ellipse_454" data-name="Ellipse 454" cx="48" cy="48" r="48" transform="translate(672 363)" fill="rgba(49,0,111,0.1)"/>
          <g id="Icon_feather-log-out" data-name="Icon feather-log-out" transform="translate(698.5 389.5)">
            <path id="Path_3097" data-name="Path 3097" d="M15.9,38.692H8.3a3.8,3.8,0,0,1-3.8-3.8V8.3A3.8,3.8,0,0,1,8.3,4.5h7.6" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
            <path id="Path_3098" data-name="Path 3098" d="M24,29.5,33.5,20,24,10.5" transform="translate(5.194 1.598)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
            <path id="Path_3099" data-name="Path 3099" d="M36.295,18H13.5" transform="translate(2.397 3.596)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
          </g>
        </g>
      </svg>
      <p>هل انت متأكد من تسجيل الخروج من حسابك ؟</p>
      <div>
        <button class="btn-logout" @click="signOut($event)">تسجيل الخروج</button>
        <button @click="openModalLogout = false">إلغاء</button>
      </div>
    </div>
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="flex items-center">
        <div class="con-img ml-3">
          <img
            key="onlineImg"
            :src="user ? user.image : '@/assets/images/elements/user2.svg'"
            :alt="user ? `${user.first_name} ${user.last_name}` : 'user-img'"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block"
            onerror="this.src='@/assets/images/elements/user2.svg'"
            loading="lazy"
          />
        </div>

        <div class="text-left leading-tight hidden sm:block ml-3" style="font-family: 'Cairo'">
          <p style="font-size: 14px; color: #6E6B7B;">
            {{ hasAccess ? client.name : user.first_name }}
          </p>
          <span style="font-size: 12px; color: #B9B9C3;">{{$t('basicClient')}}</span>
          <svg style="transform: translate(-5px, 0px);" xmlns="http://www.w3.org/2000/svg" width="8" height="4.574" viewBox="0 0 8 4.574">
            <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M14.442,10.195,11.414,7.17a.569.569,0,0,1,0-.807.577.577,0,0,1,.81,0l3.43,3.427a.571.571,0,0,1,.017.788L12.227,14.03a.572.572,0,1,1-.81-.807Z" transform="translate(14.196 -11.246) rotate(90)" fill="#6e6b7b"/>
          </svg>
        </div>
      </div>

        <div>
          <vs-dropdown-menu class="vx-navbar-dropdown">
            <ul style="min-width: 9rem" v-if="!openModalLogout">
              <vs-dropdown-item to="/setting-main-info" class="m-0 p-0">
                <li
                  class="flex py-4 px-4 cursor-pointer profile-drop">
                  <feather-icon icon="InfoIcon" svgClasses="w-5 h-5" />
                  <span class="ml-2">{{ $t('accountSettings') }}</span>
                </li>
              </vs-dropdown-item>

              <vs-divider class="m-0" />
              <li
                @click="openModalLogout = true"
                class="flex py-4 px-4 cursor-pointer logout-drop">
                <feather-icon icon="LogOutIcon" svgClasses="w-5 h-5" />
                <span class="ml-2">{{ $t('logout') }}</span>
              </li>
            </ul>
          </vs-dropdown-menu>
        </div>
      </vs-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import aclAccess from "@/mixins/hasAccess"

export default {
  mixins: [aclAccess],
  data () {
    return {
      openModalLogout: false,
    }
  },
  computed: {
    ...mapGetters('userData', ['client', 'user'])
  },
  methods: {
    ...mapActions('auth', ["logout"]),
    async signOut (event) {
      event.preventDefault()
      await this.logout()
      this.$socket.disconnect()
      window.location.reload()
      this.$router.push({name:'page-login'})
    }
  },
  created() {}
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';
.vs-dropdown--item{
  width: 100% !important;
  a{
    padding: 0px !important;
  }
  
}
.vs-dropdown--custom{
  padding: 0px !important;
  border-radius: 8px;
  overflow: hidden;
  .profile-drop, .logout-drop{
    transition: 0.3s;
  }
  .profile-drop:hover{
    background-color: $mainColor2;
    color: $mainColor1;
  }
  .logout-drop:hover{
    background-color: #d812121f;
    color: #D81212;
  }
}
.overlay-modal-logout {
  background-color: #212121;
  opacity: 30%;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100%;
  z-index: 999999;
}
.modal-logout {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 999999;
  background-color: #fff;
  right: 50%;
  transform: translateX(50%);
  top: 350px;
  padding: 24px 50px;
  border-radius: 24px;
  box-shadow: 0px 8px 21px #0000001A;
  svg {
    margin-bottom: 12px;
  }
  p {
    color: #212121;
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 12px;
    font-weight: bold;
  }
  button {
    border: none;
    background-color: $mainColor2;
    padding: 6px 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    border-radius: 6px;
    height: 38px;
    cursor: pointer;
  }
  .btn-logout {
    background-color: $mainColor1;
    color: #fff;
    margin-right: 12px;
  }
  .close-icon {
    width: 100%;
    text-align: right;
    margin-left: 48px;
    cursor: pointer;
  }
}
@media screen and (max-width: 600px) {
  .modal-logout {
    top: 100px;
    padding: 24px 12px;
    width: fit-content;
    p {
      font-size: 16px;
    }
    .close-icon {
      margin-left: 0px;
    }
  }
}
</style>