<template>
    <div  class="main-ticket" @click="checkTicketsAvailable()">
            <div class="view-support" data-tour-step="9" data-tour-inner-step="1" data-tour-inner-step2="1" 
        v-show="showType">
            <SorryPopupVue v-if="not_delay " />
            <div class="header-view-support" :class="[afterFiveOclock ? 'after-five-oclock' : '']" v-if="ticketPageIndex != 'instractions'">
                <div class="top-header">
                    <svg v-if="ticketPageIndex > 1" @click="restTicketPageIndex()" class="back-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g id="Group_2104" data-name="Group 2104" transform="translate(-1350 -211)">
                            <rect id="Rectangle_1278" data-name="Rectangle 1278" width="24" height="24" rx="5" transform="translate(1350 211)" fill="rgba(49,0,111,0.1)"/>
                            <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M13.126,11.633l4.117-4.114a.778.778,0,0,0-1.1-1.1l-4.664,4.661a.776.776,0,0,0-.023,1.072l4.684,4.694a.778.778,0,0,0,1.1-1.1Z" transform="translate(1376.471 235.074) rotate(180)" fill="#31006f"/>
                        </g>
                    </svg>
                    <div v-if="afterFiveOclock" class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.188" height="15.917" viewBox="0 0 18.188 15.917">
                            <g id="alert-triangle" transform="translate(-0.78 -2.147)">
                                <path id="Path_2801" data-name="Path 2801" d="M8.511,3.664,1.765,14.925a1.593,1.593,0,0,0,1.362,2.389H16.619a1.593,1.593,0,0,0,1.362-2.389L11.235,3.664a1.593,1.593,0,0,0-2.724,0Z" fill="none" stroke="#ff9f43" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                <line id="Line_491" data-name="Line 491" y2="4" transform="translate(9.873 7.405)" fill="none" stroke="#ff9f43" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                <line id="Line_492" data-name="Line 492" x2="0.01" transform="translate(9.872 14.129)" fill="none" stroke="#ff9f43" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                        </svg>
                        <span class="warn-ticket">تنبيه</span>
                    </div>
                    <img v-else :src="mainLogo" alt="Logo Turbo">
                    <i class="icon-close" @click="toggleShowTicket()">
                        <feather-icon icon="XIcon" class="cursor-pointer"></feather-icon>
                    </i>
                </div>
                <div class="bottom-header" v-if="ticketPageIndex != 5 && ticketPageIndex != 6">
                    <h2>{{afterFiveOclock ? 'للاسف خدمة العملاء متاحة فقط من الساعة 9 ص حتي الساعة 5 م' : 'مرحباً, نحن هنا لحل مشكلتك !'}}</h2>
                    <p>{{afterFiveOclock ? 'يمكنك تقديم الشكوي او الطلب وسيتم الرد في اقرب وقت' : 'نستغرق حوالي بعض الثواني ليقوم احد موظفينا للرد عليك'}}</p>
                </div>
                <div v-else class="bottom-header-chat">
                    <div class="official-info">
                        <img src="@/assets/images/elements/user4.svg" alt="Client" width="31">
                        <div>
                            <h6>{{assignedUser ? assignedUser : 'الاسم'}}</h6>
                            <h6 class="department">{{assigned_user ? assigned_user.role.display_name : 'خدمة العملاء'}}</h6>
                        </div>
                    </div>
                    <div class="complaint-number">
                        <h6>{{live_ticket.type == 2 ? 'شكوي رقم' : 'استفسار رقم'}} {{live_ticket.id}}
                            <span @click="copyText('copyText1')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.6" height="12" viewBox="0 0 9.6 12">
                                    <g id="Icon_ionic-ios-copy" data-name="Icon ionic-ios-copy" transform="translate(-4.5 -1.125)">
                                        <path id="Path_1276" data-name="Path 1276" d="M23.885,3.6h2.25a.15.15,0,0,0,.15-.15h0a.7.7,0,0,0-.25-.532L24.107,1.317a.935.935,0,0,0-.515-.185h0a.185.185,0,0,0-.185.185V3.13A.479.479,0,0,0,23.885,3.6Z" transform="translate(-12.185 -0.005)" fill="#31006F"/>
                                        <path id="Path_1277" data-name="Path 1277" d="M14.2,3.125v-2H10.925a.8.8,0,0,0-.8.8v8.4a.8.8,0,0,0,.8.8h6a.8.8,0,0,0,.8-.8V4.25h-2.4A1.127,1.127,0,0,1,14.2,3.125Z" transform="translate(-3.625)" fill="#31006F"/>
                                        <path id="Path_1278" data-name="Path 1278" d="M5.8,13.925v-8.3H5.3a.8.8,0,0,0-.8.8v8.8a.8.8,0,0,0,.8.8h6.4a.8.8,0,0,0,.8-.8v-.5H6.6A.8.8,0,0,1,5.8,13.925Z" transform="translate(0 -2.9)" fill="#31006F"/>
                                    </g>
                                </svg>
                            </span>
                            <input ref="copyText1" type="hidden" style="cursor: default;" :value="live_ticket.id">
                        </h6>
                        <h6 style="font-weight: normal;">{{live_ticket.complain != null ? live_ticket.complain.name_ar : live_ticket.inquiry.name_ar}}</h6>
                    </div>
                </div>
            </div>
        <div v-if="show==1">

            <div v-if="ticketPageIndex == 'instractions'" class="header-view-support">
                <div class="top-header">
                    <div class="header-instractions">
                        <svg @click="setTicketPageIndex(1)" class="back-icon" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 13L7 7L1 1" stroke="#31006F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <h6 class="instractions-title">تعليمات اضافة تذكرة دعم</h6>
                    </div>
                    <i class="icon-close" @click="toggleShowTicket()">
                        <feather-icon icon="XIcon" class="cursor-pointer"></feather-icon>
                    </i>
                </div>
            </div>
         
            <div class="body-tickets" ref="bodyTickets">
                <TypesTickets :ticketPageIndex="ticketPageIndex" />
            </div>
</div>
<div v-else-if="show==0" class="flex items-center justify-center flex-col h-full " style="transform: translateY(-70px);">
        <div >
                    <div class="top-header">
                        <div class="header-instractions">
                            <h6 class="instractions-title bold" style="color: rgb(49, 0, 111); font-weight: bold; font-size: 16px;">   عفوا لقد انتهى عدد التذاكر المتاحة يوميا</h6>
                        </div>
                    </div>
                </div>
                <p class="flex items-center justify-center  "  style="color: rgb(49, 0, 111); font-weight: bold; font-size: 16px; margin-top: 10px;">برجاء المحاولة مرة اخرى بعد 24 ساعة</p>
    </div>
    <div class=" w-10 mx-auto" style="transform: translateY(150px);" v-else>
        <SpinnerColor ></SpinnerColor>
    </div>
</div>

    <div v-if="hideChat" class="flex gap-2 items-center justify-center">
        <i class="icon-ticket"  @click="toggleShowTicket(), checkTime">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.243" height="29.248" viewBox="0 0 29.243 29.248">
                <g id="Icon_ionic-ios-chatboxes" data-name="Icon ionic-ios-chatboxes" transform="translate(-3.375 -3.375)">
                    <path id="Path_2505" data-name="Path 2505" d="M29.946,11.981H14.365a2.683,2.683,0,0,0-2.679,2.679V25.509a2.677,2.677,0,0,0,2.679,2.672H23.28a.768.768,0,0,1,.534.225l4.43,4.085c.246.239.654.141.654-.2V28.73c0-.422.267-.555.689-.555h.07a2.927,2.927,0,0,0,2.96-2.672V14.66A2.668,2.668,0,0,0,29.946,11.981Z" fill="#fff"/>
                    <path id="Path_2506" data-name="Path 2506" d="M12.262,10.259H24.736V5.667a2.293,2.293,0,0,0-2.292-2.292H5.667A2.293,2.293,0,0,0,3.375,5.667V17.283a2.293,2.293,0,0,0,2.292,2.292h4.3V12.551A2.3,2.3,0,0,1,12.262,10.259Z" fill="#fff"/>
                </g>
            </svg>
        </i>

    </div>

    </div>
</template>

<script>
import TypesTickets from './TypesTickets.vue';
import copyText from '@/mixins/helper.js';
import SorryPopupVue from './SorryPopup.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import SpinnerColor from '@/views/components/table-components/SpinnerColor2.vue'
import axios from 'axios';
import $ from 'jquery';


export default {
    mixins: [copyText],
    data() {
        return {
            afterFiveOclock: false,
            status: '',
            assignedUser: '',
            mainLogo: process.env.VUE_APP_CONFIGS_MAIN_LOGO,
            mainLogoWhite: process.env.VUE_APP_CONFIGS_MAIN_LOGO_WHITE,
            show: null,
            hideChat:false,
        }
    },
    components: {
        TypesTickets,
        SorryPopupVue,
        SpinnerColor
    },
    computed: {
        ...mapGetters('ticket', ['live_ticket', 'assigned_user']),
        ...mapState('ticket', ['ticketPageIndex', 'showType', 'not_delay']),
        ...mapState('userData', ['user','new_support_system_user','support_system_user_id']),
        checkTime(){
            const date = new Date();
            const time = date.getHours();
            if(time >= 17 || time < 9){
                this.afterFiveOclock = true
            }else{
                this.afterFiveOclock = false
            }
        },
        changeTicket() {
            if (this.live_ticket) {
                this.$socket.emit('join', {name: `ticket-status-${this.live_ticket.id}-changed`})
                this.$socket.on(`ticket-status-${this.live_ticket.id}-changed`, (data) => {
                    this.status = parseInt(data.body.status)
                    this.assignedUser = data.body.assigned_user.full_name
                });
            }
        }
    },
    watch: {
        assigned_user: {
            handler: function() {
                if (this.assigned_user) {
                    this.assignedUser = this.assigned_user.full_name
                    this.changeTicket
                }
            },
        },
        support_system_user_id: {
            handler: function() {
                const self = this;
                if(this.new_support_system_user){
                    $.getScript("https://support.turbo.info/js/min/jquery.min.js", () => {
                        const url ='https://support.turbo.info';
                        window.SB_INIT_URL = url;
                        window.SB_LANG = 'ar';

                        var SB_LANG = 'ar';

                        const token = this.user.support_system_token;
                        const userId = this.support_system_user_id;

                        $.getScript('https://support.turbo.info/js/main.js?lang=ar');
                    
                        jQuery(document).on("SBReady", function () {
                            var SB_LANG = 'ar';
                            SBF.login("", "", userId, token, () => { SBChat.initChat(); });
                        });
                    });
                }else{
                    this.hideChat=true
                }
            },
        }
    },
    methods: {
        ...mapActions('ticket', ['toggleShowTicket', 'nextPage', 'previousPage', 'newTicket', 'setTicketPageIndex']),
        scrollToBottom () {
            this.$nextTick(() => {
                if(this.ticketPageIndex >= 5){
                    var messagesBox = this.$refs.bodyTickets
                    messagesBox.scrollTop = messagesBox.scrollHeight
                }
            })
        },
        restTicketPageIndex(){
            if (this.ticketPageIndex == 5 || this.ticketPageIndex == 6) this.newTicket()
            else this.previousPage()
        },
        async checkTicketsAvailable(){
            const response =await axios.get(`ticket/can_create`);
            const timer=setTimeout(() => {
                this.show=response.data.success;
                clearTimeout(timer)
            },3000)
        },

  
    },

    mounted() {
        this.checkTicketsAvailable();
    },
    unmounted () {
        this.$socket.emit('leave', {name: `ticket-status-${this.live_ticket.id}-changed`})
        this.$socket.off(`ticket-status-${this.live_ticket.id}-changed`)

    },
    updated () {
        this.scrollToBottom()
    },    created(){
        this.scrollToBottom()
        this.notDelay = this.not_delay
    },
 



}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vuexy/_supportTicket.scss';
</style>
