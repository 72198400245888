<template>
  <div class="body">
    <div class="inner-body">
      <img src="@/assets/images/elements/sorry.svg" alt="Sorry" />
      <h5>هذا الطرد ليس متأخر بالفعل وسيتم تسليمة خلال المدة المتفق عليها .</h5>
      <buttonVue
        title="حسناً"
        @action="createNewTicket()"
        width="100%"
        borderRadius="4px"
      />
    </div>
  </div>
</template>

<script>
import buttonVue from "@/layouts/components/button.vue";
import { mapActions } from "vuex";
export default {
  components: { buttonVue },
  methods: {
    ...mapActions("ticket", ["toggleShowTicket", "newTicket", "setNotDelay"]),
    createNewTicket() {
      this.setNotDelay();
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: #00000080;
  padding: 54px 28px 8px 28px;
  .inner-body {
    background: #fff;
    box-shadow: 0px 3.36px 6.72px 2.52px rgba(0, 0, 0, 0.15),
      0px 0.84px 2.52px rgba(0, 0, 0, 0.3);
    border-radius: 23.52px 23.52px 0px 0px;
    display: flex;
    align-items: center;
    padding: 30px 29px 39px 29px;
    flex-direction: column;
    gap: 29px;
    h5 {
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      line-height: 23px;
      color: #000;
    }
    .button-vue {
      padding: 6.5px 0px;
      font-size: 14px;
      font-weight: 600;
      line-height: 27px;
    }
  }
}
</style>
