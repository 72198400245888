<template>
  <div class="relative">
    <v-tour :options="tourOptions" name="navMenuTour" :steps="steps"></v-tour>
    <div
      class="vx-navbar-wrapper nav-menu-wrapper"
      :style="[
        $store.state.stepTour == 0 ||
        $store.state.stepTour == 1 ||
        $store.state.stepTour == 8
          ? { zIndex: 99999 }
          : { zIndex: 999 },
      ]"
    >
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColor"
      >
        <ul class="menu-items flex flex-wrap w-full items-center">
          <li
            class="menu-item"
            v-for="(item, index) in navMenuItems"
            @click="trackEventGoogle(item)"
            :key="index"
            :class="{
              'new-order': item.slug === 'newOrder',
              'new-mission': item.url === '/addMission',
              'products-nav': item.url === '/products',
              reports: item.i18n === 'reports',
            }"
          >
            <!-- If header -->
            <template v-if="item.header">
              <h-nav-menu-header :header="item" class="menu-header relative" />
            </template>

            <!-- If it's group -->
            <template v-else-if="item.submenu">
              <h-nav-menu-group
                class="menu-group relative py-4"
                bottom
                :key="`group-${index}`"
                :group="item"
                :groupIndex="index"
                :open="checkGrpChildrenActive(item)"
              />
            </template>

            <!-- If it's link -->
            <div v-else-if="item.url" class="menu-link">
              <h-nav-menu-item
                class="relative py-4 cursor-pointer"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                :target="item.target"
                :isDisabled="item.isDisabled"
                :slug="item.slug"
              >
                <span class="truncate">{{ $t(item.i18n) || item.name }}</span>
                <vs-chip :color="item.tagColor" v-if="item.tag">{{
                  item.tag
                }}</vs-chip>
              </h-nav-menu-item>
            </div>
          </li>
        </ul>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import HNavMenuGroup from "./HorizontalNavMenuGroup.vue";
import HNavMenuHeader from "./HorizontalNavMenuHeader.vue";
import HNavMenuItem from "./HorizontalNavMenuItem.vue";
import tour from "@/mixins/tour.js";
import { mapActions } from "vuex";
import gTag from "@/mixins/gtag.js"

export default {
  mixins: [tour, gTag],
  data() {
    return {
      steps: [
        {
          target: '[data-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: "اضف طرد",
          },
          content: `من هنا يمكنك اضافة طرد جديد للشحن وايضاً يمكن اضافة تفاصيل اخري في هذة الفقرة النصية`,
        },
        {
          target: '[data-tour-step="2"]',
          header: {
            title: "اضف مهمة",
          },
          content: `يمكنك اضافة طلبات استلام الطرود الجديدة (pickup). ويمكنك ايضا طلب استلام تحصيلاتك من هنا`,
        },
        {
          target: '[data-tour-step="5"]',
          header: {
            title: "تتبع طلبك بسهولة",
          },
          content: `قم بكتابة كود الشحنة وتتبعها بسهولة من هنا`,
        },
        {
          target: '[data-tour-step="7"]',
          header: {
            title: "إعدادات الحساب",
          },
          content: `من هنا يمكنك الربط ب ال API مع تربو و يمكنك تغيير كلمة المرور`,
        },
        {
          target: '[data-tour-step="9"]',
          header: {
            title: "اضف تذكرة جديدة",
          },
          content: `يمكنك التحدث مع الدعم الفني عن طريق إنشاء تذكرة من هنا , مع العلم مواعيد الدعم الفني من 9ص حتي 5م`,
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-tour-inner-step="1"]',
          header: {
            title: "اختيار نوع التذكرة",
          },
          content: `يرجي اختيار نوع التذكرة من هنا مع العلم ان اختيارك للنوع بشكل صحيح يساعدناعلي حل الامر بشكل اسرع`,
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-tour-inner-step2="1"]',
          header: {
            title: "اختيار نوع التذكرة",
          },
          content: `يرجي اختيار نوع التذكرة من هنا مع العلم ان اختيارك للنوع بشكل صحيح يساعدناعلي حل الامر بشكل اسرع`,
          params: {
            placement: "top",
          },
        },
        {
          target: '[data-tour-inner-step="2"]',
          header: {
            title: "تفاصيل التذكرة",
          },
          content: `من هنا يمكنك معرفة تفاصيل التذكرة والتواصل مع الدعم الفني`,
        },
        {
          target: '[data-tour-step="2"]',
          header: {
            title: "اضف مهمة",
          },
          content: `يمكنك اضافة طلبات استلام الطرود الجديدة (pickup). ويمكنك ايضا طلب استلام تحصيلاتك من هنا`,
        },
      ],
    };
  },
  props: {
    // navbarColor  : { type: String, default: "#fff", },
    navMenuItems: { type: Array, required: true },
  },
  components: {
    HNavMenuGroup,
    HNavMenuHeader,
    HNavMenuItem,
  },
  computed: {
    navbarColor() {
      return this.$store.state.theme === "dark" ? "#10163a" : "#fff";
    },
  },
  methods: {
    ...mapActions(["updateStepTour", "walkthrough"]),
    // ...mapActions('auth', ['walkthrough']),
    checkGrpChildrenActive(group) {
      const path = this.$route.fullPath;
      let active = false;
      const routeParent = this.$route.meta
        ? this.$route.meta.parent
        : undefined;

      if (group.submenu) {
        group.submenu.forEach((item) => {
          if (active) return true;
          if ((path === item.url || routeParent === item.slug) && item.url) {
            active = true;
          } else if (item.submenu) {
            this.checkGrpChildrenActive(item);
          }
        });
      }

      return active;
    },
    trackEventGoogle(item) {
      if (item.url === '/addMission') {
        this.googleEventTrack('الضغط على اضافة مهمة', 'navmenu', 'add_new_mission_item_nav_menu')
      }
    }
  },

  mounted() {
    if (this.runWalkthrough()) {
      this.walkthrough();
      this.$watch(
        () => {
          return this.$tours.navMenuTour.currentStep;
        },
        (val) => {
          this.$store.dispatch("updateStepTour", val);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      );
      const li = document.getElementsByClassName("new-order")[0];
      const liMission = document.getElementsByClassName("new-mission")[0];
      const reports = document.querySelector(
        ".reports .nav-header .header-label"
      );
      li.setAttribute("data-tour-step", 1);
      liMission.setAttribute("data-tour-step", 2);
      reports.setAttribute("data-tour-step", 6);
      // this.startTour("navMenuTour");
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/horizontalNavMenu.scss";
@import "@/assets/scss/vuexy/_variables.scss";

.nav-header {
  .nav-link {
    color: #626262;
    border-bottom: 1px solid #70707038;
  }
}
.new-order {
  font-weight: 600;
  position: absolute;
  right: 176px;
  border: solid 1px $mainColor1;
  border-radius: 6px;
  width: 151px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $mainColor1;
  background-color: #fff;
  .nav-header {
    width: 100%;
    .header-label {
      width: 100%;
      justify-content: center;
      margin: 0px !important;
    }
  }
  .nav-link {
    color: #626262;
    font-weight: 400;
  }
  .header-active {
    padding: 7px 6px !important;
  }
  .router-link-active {
    color: $mainColor1 !important;
  }
  .header-open {
    background-color: #fff !important;
    border: solid 1px $mainColor1;
    width: 151px !important;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
}
.nav-link:hover {
  background-color: $mainColor2;
  color: $mainColor1;
  transform: translateX(0px) !important;
}
.h-nav-menu-dd {
  border-radius: 8px;
  padding: 0px !important;
  overflow: hidden;
}
.new-mission {
  font-weight: 600;
  position: absolute;
  right: 24px;
  background-color: $mainColor2;
  border-radius: 6px;
  width: 128px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $mainColor1;

  .nav-link {
    background-color: $mainColor2 !important;
    width: 128px !important;
    padding: 0px !important;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 0px;
  }
}
.products-nav {
  position: absolute;
  right: 351px;
  color: #fff;
  background-color: $mainColor1;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 36px;
  cursor: pointer;
  .h-nav-menu-item {
    padding: 0px !important;
    &:hover .nav-link {
      background-color: unset;
    }
    a {
      padding: 0px;
      &:hover {
        color: #fff;
      }
    }
  }
}
.addExcel:hover {
  a {
    color: #28c76f !important;
    background-color: #28c76f1a !important;
  }
}
</style>
