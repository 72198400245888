import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import SkeletonLoading from "@/layouts/components/SkeletonLoading.vue";
export default {
  data() {
    return {
      popupRate: false,
      popupCancelMission: false,
      notifiableId: undefined,
      loadingSubmitTicket: false,
      confirmRateLoad: false,
      rates: [
        {
          value: 1,
          imgSrc: require("@/assets/images/elements/rate5.svg"),
          classNameParent: "rate5",
          className: "active-rate5",
          name: "غير راضي جدا",
        },
        {
          value: 2,
          imgSrc: require("@/assets/images/elements/rate4.svg"),
          classNameParent: "rate4",
          className: "active-rate4",
          name: "غير راضي",
        },
        {
          value: 3,
          imgSrc: require("@/assets/images/elements/rate3.svg"),
          classNameParent: "rate3",
          className: "active-rate3",
          name: "عادي",
        },
        {
          value: 4,
          imgSrc: require("@/assets/images/elements/rate2.svg"),
          classNameParent: "rate2",
          className: "active-rate2",
          name: "راضي",
        },
        {
          value: 5,
          imgSrc: require("@/assets/images/elements/rate1.svg"),
          classNameParent: "rate1",
          className: "active-rate1",
          name: "راضي جدا",
        },
      ].reverse(),
      activeCalss: "",
      rateVlaue: "",
      description: "",
      errMsg: false,
      colorRate: "",
      popupImage: false,
      popupImageData: {},
      imgLoading: true,
      cdnUrl: process.env.VUE_APP_CDN_URL,
    };
  },
  components: {
    SkeletonLoading,
  },
  computed: {
    ...mapGetters("dataList", [
      "notfication",
      "success_api_rate",
      "message",
      "detail_of_order",
    ]),
    ...mapGetters("ticket", ["success", "error_msg", "live_ticket"]),
  },
  methods: {
    ...mapActions("dataList", ["rateOrder"]),
    ...mapActions("ticket", ["createTicket"]),
    clickNotfication(ntf) {

      if (ntf.type !== null) {
        switch (ntf.type) {
          case "App\\Models\\Order":
            if (ntf.notifiable_code)
              this.$router.push(`/OrderDetails/${ntf.notifiable_code || ""}`);
            break;

          case "App\\Models\\Mission":
            this.$router.push(`/MissionDetails/${ntf.notifiable_id}`);
            break;

          case "App\\Models\\Mission_cancel":
            this.popupCancelMission = true;
            this.notifiableId = ntf.notifiable_id;
            break;

          case "App\\Models\\Order\\Rate":
            if (ntf.notifiable_code)
              this.$router.push({
                path: `/OrderDetails/${ntf.notifiable_code || ""}`,
                query: { popupRate: true },
              });
            break;

          case "App\\Models\\ExcelExport":
            const url = ntf.redirect_url
            const isFullUrl = url.search('http') === -1 ? false : true
            if(isFullUrl){
              window.location.href = ntf.redirect_url;
            }else{
              window.location.href = process.env.VUE_APP_BASE_URL_EXCEL + ntf.redirect_url;
            }
            break;

          case "App\\Models\\Ticket":
            this.$router.push({
              path: `/apps/chat/${ntf.notifiable_id}`,
              query: { type: 1 },
            });
            break;

          case "App\\Models\\OrderChangeRequest":                        
            window.customEvents.$emit('orderRequestChangeOpen', { orderId: ntf.notifiable_id });
            break;

          default:
            break;
        }
      } else if (ntf.redirect_url !== null && ntf.redirect_url !== "") {
        window.location.href = ntf.redirect_url;
      }

      if (ntf.img) {
        this.popupImageData = ntf;
        this.imgLoading = true;
        this.popupImage = true;
      }
      if (this.$refs.dropdownMenu) {
        this.$refs.dropdownMenu.vsDropdownVisible = false;
        this.$refs.dropdownMenu.$children[0].dropdownVisible = false;
      }
    },
    closePopup() {
      this.popupCancelMission = false;
      this.popupRate = false;
      this.popupImage = false;
      this.errMsg = false;
    },
    activeRate(rate) {
      $(`.single-ticket img`).removeAttr("class");
      $(`.${rate.classNameParent} img`).addClass(rate.className);
      this.activeCalss = $(`.${rate.classNameParent} img`).addClass(
        rate.className
      )[0];
      this.rateVlaue = rate.value;
    },
    async postRate() {
      this.confirmRateLoad = true;
      const obj = {
        order_id: this.detail_of_order.id,
        rate: this.rateVlaue,
        note: this.description,
      };
      await this.rateOrder(obj);
      if (this.success_api_rate == 1) {
        this.errMsg = false;
        this.notficationTost(true, "تم التقييم بنجاح");
        this.$refs.notificationToast.click();
        setTimeout(() => {
          this.closePopup();
        }, 1000);
      } else {
        this.errMsg = true;
      }
      this.confirmRateLoad = false;
    },
    async confirmRateOrder() {
      if (this.rateVlaue != 5 && this.rateVlaue != 4) {
        this.$validator.validateAll().then(async (result) => {
          if (result) {
            this.postRate();
          }
        });
      } else {
        this.postRate();
      }
    },
    async createNewTicket() {
      this.loadingSubmitTicket = true;
      let obj = {
        entityId: this.notifiableId,
        description: "تم الغاء المهمة بدون الرجوع  والاتصال به",
        typeTicket: 2,
        missionComplaint: 1,
      };
      await this.createTicket(obj);
      this.loadingSubmitTicket = false;
      if (this.success == 1) {
        this.errMsg = false;
        this.$router.push({
          path: `/apps/chat/${this.live_ticket.id}`,
          query: { type: 1 },
        });
        this.closePopup();
      } else {
        this.errMsg = true;
      }
    },
    cancelMission() {
      this.popupCancelMission = false;
      this.$router.push(`/MissionDetails/${this.notifiableId}`);
    },
    onImageLoad() {
      this.imgLoading = false;
    },
  },
};
