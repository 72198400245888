<template>
  <ul class="instractions-list" v-if="!loading">
    <li class="instractions-item text-danger" v-if="err_instructions">
      {{ err_instructions }}
    </li>
    <div v-for="(instraction, index) in ticket_instructions">
      <li class="instractions-item"  v-if="ticket_instructions.length-1 !==index">
      {{ instraction.name_ar }}
    </li>
    </div>
    <li class="instractions-item " dir="rtl">
<span dir="rtl">      يرجى قراءة الشروط والأحكام جيدًا قبل إضافة تذكرة الدعم.
  <a href=" https://portal.turbo-eg.com/faq/faq" target="_blank">للاستفسارات</a>-  
      <a href=" https://portal.turbo-eg.com/termsAndConditions" target="_blank">للشروط والأحكام</a>
</span>
 
</li>

  </ul>
  <ul class="flex w-full h-full justify-center items-center" v-else>
    <SpinnerColor2 />
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SpinnerColor2 from "@/views/components/table-components/SpinnerColor2.vue";
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: { ...mapActions("ticket", ["gitInstructions"]) },
  computed: {
    ...mapState("ticket", ["ticket_instructions", "err_instructions"])
  },
  components: { SpinnerColor2 },
  async created() {
    this.loading = true;
    if (!this.ticket_instructions.length) {
      await this.gitInstructions();
    }
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_supportTicket.scss";
</style>
