<template>
    <div>
        <div class="display-img" v-if="imgDisplay">
            <div style="position:relative">
                <feather-icon svgClasses="text-white" icon="XIcon" style="position: absolute !important; color: #fff; margin-bottom: 12px; left: 0%; top: -25px" @click="imgDisplay = false" class="cursor-pointer"></feather-icon>
                <img :src="imgSrc" alt="Img Chat">
            </div>
            <a :href="imgSrc" target="_blank" download>
                <svg xmlns="http://www.w3.org/2000/svg" width="16.834" height="16.834" viewBox="0 0 16.834 16.834">
                    <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(0.75 0.75)">
                        <path id="Path_3047" data-name="Path 3047" d="M19.834,22.5v3.408a1.7,1.7,0,0,1-1.7,1.7H6.2a1.7,1.7,0,0,1-1.7-1.7V22.5" transform="translate(-4.5 -12.277)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Path_3048" data-name="Path 3048" d="M10.5,15l4.259,4.259L19.019,15" transform="translate(-7.092 -9.037)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Path_3049" data-name="Path 3049" d="M18,14.723V4.5" transform="translate(-10.333 -4.5)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                </svg>
                تحميل الصورة
            </a>
        </div>
        <div>
            <div class="client-msg">
                <img src="@/assets/images/elements/user2.svg" alt="Employee" width="31">
                <p class="content-msg">
                    {{live_ticket.description}}
                </p>
            </div>
            <template v-if="live_ticket.image">
                <div class="client-msg" v-for="(image, index) in live_ticket.image" :key="index">
                    <img src="@/assets/images/elements/user2.svg" alt="Employee" width="31" />
                    <p :class="{'image-msg': checkImageFormat(image), 'msg-file': !checkImageFormat(image), 'content-msg': !image}"> 
                        <span @click="imgSrc = image, imgDisplay = true" v-if="checkImageFormat(image)">
                            <img class="image-file" :src="image" alt="Image" loading="lazy">
                        </span>
                    </p>
                </div>
            </template>
            
            <div v-if="messages.length != 0" style="margin-bottom: 30px;">
                <div v-for="(live_message, index) in messages" :key="index" :class="live_message.is_client_message == 1 ? 'client-msg' : 'employee-msg'">
                    <img v-if="live_message.is_client_message == 1" src="@/assets/images/elements/user2.svg" alt="Employee" width="31">
                    <img v-else src="@/assets/images/elements/user3.svg" alt="Client" width="31">
                    <p :class="{'image-msg': checkImageFormat(live_message.image), 'msg-file': !checkImageFormat(live_message.image), 'content-msg': !live_message.image}"> 
                        <span @click="imgSrc = live_message.image, imgDisplay = true" v-if="checkImageFormat(live_message.image)">
                            <img class="image-file" :src="live_message.image" alt="Image" loading="lazy">
                        </span>
                        <a :title="live_message.file_name" :href="live_message.image" v-else-if="live_message.image" :download="new Date().toLocaleDateString()">
                            <div class="inner-file">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="44" viewBox="0 0 40 44">
                                    <g id="Group_2163" data-name="Group 2163" transform="translate(-243 -534)">
                                        <rect id="Rectangle_1384" data-name="Rectangle 1384" width="40" height="44" rx="6" transform="translate(243 534)" fill="rgba(49,0,111,0.1)"/>
                                        <g id="Icon_feather-file" data-name="Icon feather-file" transform="translate(249 542)">
                                        <path id="Path_3050" data-name="Path 3050" d="M15.541,3H8.12A2.12,2.12,0,0,0,6,5.12V22.083A2.12,2.12,0,0,0,8.12,24.2H20.842a2.12,2.12,0,0,0,2.12-2.12V10.421Z" transform="translate(0 0)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_3051" data-name="Path 3051" d="M19.5,3v7.421h7.421" transform="translate(-3.959 0)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        </g>
                                    </g>
                                </svg>
                                <div style="text-align: right">
                                    <p>{{live_message.file_name}}</p>
                                    <span>{{live_message.file_size}}</span>
                                </div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.202" height="18.202" viewBox="0 0 18.202 18.202">
                                <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(-3.5 -3.5)">
                                    <path id="Path_3047" data-name="Path 3047" d="M20.7,22.5v3.6a1.8,1.8,0,0,1-1.8,1.8H6.3a1.8,1.8,0,0,1-1.8-1.8V22.5" transform="translate(0 -7.199)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_3048" data-name="Path 3048" d="M10.5,15,15,19.5,19.5,15" transform="translate(-2.4 -4.199)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_3049" data-name="Path 3049" d="M18,15.3V4.5" transform="translate(-5.399)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </a>
                        <span v-html="checkLink(live_message)" :style="live_message.is_client_message == 1 ? 'color: #fff' : 'color: #31006F;'"></span>
                        <!-- {{live_message.message}} -->
                    </p>
                </div>
            </div>
            <div class="submit-msg">
                <input :style="[imageUrl ? {'paddingRight': 63+'px'} : '']" :placeholder="placeholdeInput" v-model="message" @keyup.enter="sendMessage">
                <div v-if="imageUrl" class="image-information">
                    <img :src="imageUrl" alt="Image" />
                    <div class="inner-image-information">
                        <svg @click="removeImage()" xmlns="http://www.w3.org/2000/svg" width="11.558" height="11.558" viewBox="0 0 11.558 11.558">
                            <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M9.154,3.375a5.779,5.779,0,1,0,5.779,5.779A5.778,5.778,0,0,0,9.154,3.375Zm1.464,7.871L9.154,9.782,7.69,11.246a.444.444,0,1,1-.628-.628L8.526,9.154,7.062,7.69a.444.444,0,0,1,.628-.628L9.154,8.526l1.464-1.464a.444.444,0,0,1,.628.628L9.782,9.154l1.464,1.464a.446.446,0,0,1,0,.628A.441.441,0,0,1,10.618,11.246Z" transform="translate(-3.375 -3.375)" fill="#31006f"/>
                        </svg>
                    </div>
                </div>
                <div class="attach-file mr-4" @click="openPopupFiles =! openPopupFiles">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 11.763 22.456">
                    <path id="Icon_ionic-md-attach" data-name="Icon ionic-md-attach" d="M19.909,7.062V18.825a4.277,4.277,0,1,1-8.555,0V6.527a2.673,2.673,0,1,1,5.347,0v12.3a1.069,1.069,0,0,1-2.139,0V8.666h-1.6V18.825a2.673,2.673,0,0,0,5.347,0V6.527a4.277,4.277,0,0,0-8.555,0v12.3a5.881,5.881,0,0,0,11.763,0V7.062Z" transform="translate(-9.75 -2.25)" fill="#6e6b7b"/>
                    </svg>
                    <template v-if="openPopupFiles">
                        <div class="popup-files">
                            <div class="popup-file">
                                <input type="file" @change="previewFiles($event, 1)" accept="image/png, imgae/jpeg, imgae/jpg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.119" height="14.119" viewBox="0 0 14.119 14.119">
                                    <g id="Icon_feather-image" data-name="Icon feather-image" transform="translate(0.6 0.6)">
                                        <path id="Path_3032" data-name="Path 3032" d="M5.908,4.5h9.855A1.408,1.408,0,0,1,17.17,5.908v9.855a1.408,1.408,0,0,1-1.408,1.408H5.908A1.408,1.408,0,0,1,4.5,15.763V5.908A1.408,1.408,0,0,1,5.908,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                                        <path id="Path_3033" data-name="Path 3033" d="M12.612,11.556A1.056,1.056,0,1,1,11.556,10.5,1.056,1.056,0,0,1,12.612,11.556Z" transform="translate(-7.684 -7.684)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                                        <path id="Path_3034" data-name="Path 3034" d="M18.763,18.52,15.243,15,7.5,22.743" transform="translate(-6.092 -10.073)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                                    </g>
                                </svg>
                                صورة
                            </div>
                            <div class="popup-file">
                                <input type="file" @change="previewFiles($event, 2)" accept=".pdf, .docx, .xlsx">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.87" height="17.038" viewBox="0 0 13.87 17.038">
                                    <g id="Icon_feather-file" data-name="Icon feather-file" transform="translate(-5.4 -2.4)">
                                        <path id="Path_3035" data-name="Path 3035" d="M13.127,3H7.584A1.584,1.584,0,0,0,6,4.584v12.67a1.584,1.584,0,0,0,1.584,1.584h9.5a1.584,1.584,0,0,0,1.584-1.584V8.543Z" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                                        <path id="Path_3036" data-name="Path 3036" d="M19.5,3V8.543h5.543" transform="translate(-6.373 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                                    </g>
                                </svg>
                                ملف
                            </div>
                        </div>
                    </template>
                </div>
                <svg @click="sendMessage" :class="{'disable-attach-file': sendFile}" xmlns="http://www.w3.org/2000/svg" width="18.718" height="18.898" viewBox="0 0 18.718 18.898">
                    <path id="Icon_ionic-ios-send" data-name="Icon ionic-ios-send" d="M22.631,4.545l-17.9,7.876a.416.416,0,0,0,.015.753L9.59,15.935a.775.775,0,0,0,.892-.089l9.545-8.309c.063-.054.214-.158.273-.1s-.034.212-.088.276l-8.258,9.392a.791.791,0,0,0-.078.94l3.164,5.124a.41.41,0,0,0,.741-.01l7.4-18.07A.412.412,0,0,0,22.631,4.545Z" transform="translate(-4.503 -4.503)" fill="#31006f"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import notify from "@/mixins/helper.js"
import imageFormats from '@/mixins/imageFormats.js'

export default {
    mixins: [notify, imageFormats],
    data() {
        return {
            message: '',
            messages: [],
            imageTitleName: 'قم بإختيار صورة',
            rawFile: '',
            imgSrc: '',
            imgDisplay: false,
            openPopupFiles: false,
            sendFile: false,
            placeholdeInput: "اكتب رسالتك",
            imagesSrc: '',
            imageUrl: '',
            imageSize: '',
            imageName: ''
        }
    },
    computed: {
        ...mapGetters('ticket', ['live_ticket', 'live_message_data'])
    },
    methods: {
        ...mapActions('ticket', ['replyTicket']),
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return 'n/a'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
            if (i === 0) return `${bytes} ${sizes[i]})`
            return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
        },
        async sendMessage(e){
            if(this.validate() && !this.sendFile){
                this.placeholdeInput = "جاري الإرسال ..."
                this.sendFile = true
                e.preventDefault()
                let obj = {
                    id: this.live_ticket.id,
                    message: this.message,
                }
                if(this.rawFile != ''){
                    obj.image = this.rawFile
                }
                await this.replyTicket(obj)
                this.message = ''
                this.rawFile = ''
                this.sendFile = false
                this.imageTitleName = 'قم بإختيار صورة'
                this.placeholdeInput = "اكتب رسالتك"
                this.removeImage()
            }
        },
        removeImage() {
            this.rawFile = ''
            this.imagesSrc = '',
            this.imageUrl = '',
            this.imageSize = '',
            this.imageName = ''
        },
        async previewFiles(e, type){
            const files = e.target.files
            this.rawFile = files[0]
            const typeFile = this.rawFile.type.split('/').pop()
            if (type == 1) {
                var reader = new FileReader();
                this.imageSize = this.bytesToSize(this.rawFile.size)
                this.imageName = this.rawFile.name
                reader.onload = (e) => {
                    this.imageUrl = (e.target.result)
                };
                reader.readAsDataURL(this.rawFile);
            }
            if(type == 2){
                if(!this.imageFormats.includes(typeFile)){
                    this.sendFile = true
                    this.placeholdeInput = "جاري إرسال الملف ..."
                    let obj = {
                        id: this.live_ticket.id,
                        message: this.message,
                        image: this.rawFile,
                        fileSize: this.bytesToSize(this.rawFile.size),
                        fileName: this.rawFile.name
                    }
                    await this.replyTicket(obj)
                    this.rawFile = ""
                    this.sendFile = false
                    this.placeholdeInput = "اكتب رسالتك"
                }
            }
            this.imageTitleName = this.rawFile.name
        },
        validate(){
            if(this.rawFile != '' || this.message != ''){
                return true
            }else{
                return false
            }
        },
        checkLink(msg) {
            let regx = /(?:https?|ftp):\/\/[\n\S]+/g;
            if (regx.test(msg.message)) {
                const str = msg.message.match(regx)[0];
                let link;
                let color;
                if (msg.is_client_message == 1) {
                    color = "#FFF";
                } else {
                    color = "";
                }
                link = msg.message.replace(
                regx,
                `<u><a target="_blank" style="color: ${color}" href="${str}">${str}</a></u>`
                );
                return link;
            } else {
                return msg.message;
            }
        },
    },
    mounted (){
        this.$socket.emit('join', {name: `ticket-reply-${this.live_ticket.id}-created`})
        this.$socket.on(`ticket-reply-${this.live_ticket.id}-created`, (data) => {
            this.messages.push(data.body)
        });
    },
    unmounted () {
        this.$socket.emit('leave', {name: `ticket-reply-${this.live_ticket.id}-created`})
        this.$socket.off(`ticket-reply-${this.live_ticket.id}-created`)
        this.$socket.off(`ticket-status-${this.single_ticket.id}-changed`)
    },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vuexy/_supportTicket.scss';
</style>