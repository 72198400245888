<template>
    <div>
        <sidebar-help-center :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" />
        <i class="mt-4 cursor-pointer" @click="addDataSidebar()">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-5" width="18" height="18" viewBox="0 0 18 18">
                <path id="Path_1649" data-name="Path 1649" d="M452.2,187.375a9,9,0,1,0,9,9A9,9,0,0,0,452.2,187.375Zm0,16.594a7.594,7.594,0,1,1,7.594-7.594A7.59,7.59,0,0,1,452.2,203.969Zm2.813-9.264a2.817,2.817,0,0,1-2.109,2.723v1.144a.7.7,0,1,1-1.406,0v-1.758a.7.7,0,0,1,.7-.7,1.406,1.406,0,1,0-1.406-1.406.7.7,0,1,1-1.406,0,2.813,2.813,0,0,1,5.625,0Zm-1.933,5.977a.879.879,0,1,1-.879-.879A.879.879,0,0,1,453.083,200.682Z" transform="translate(-443.204 -187.375)" fill="#6e6b7b"/>
            </svg>
        </i>
    </div>
</template>

<script>
import SidebarHelpCenter from './SidebarHelpCenter.vue'
import gTag from "@/mixins/gtag.js"

export default {
    mixins: [gTag],
    data(){
        return{
            addNewDataSidebar: false,
        }
    },
    components: {
        SidebarHelpCenter,
    },
    methods: {
        toggleDataSidebar (val = false) {
            this.addNewDataSidebar = val
        },
        addDataSidebar () {
            this.googleEventTrack('الضغط على ايقون مركز المساعدة', 'navbar', 'help_center_in_navbar')
            this.toggleDataSidebar(true)
        }
    }
}
</script>