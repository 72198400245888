<template>
  <div class="navbar-bookmarks flex items-center">
    <div v-if="windowWidth >= 1200" style="position: relative;" class="manage">
      <button class="account-manage-btn" @click="accountManageActive =! accountManageActive, googleEventTrack('الضغط على زر مدير حسابك', 'navbar', 'manager_of_your_account_in_navbar')" ref="dropdownMenu">
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="17.379" height="17.41" viewBox="0 0 17.379 17.41">
            <path id="Icon_feather-phone-call" data-name="Icon feather-phone-call" d="M12.954,4.526a3.782,3.782,0,0,1,2.988,2.988M12.954,1.5a6.808,6.808,0,0,1,6.013,6.006m-.756,6.036v2.269a1.513,1.513,0,0,1-1.649,1.513A14.969,14.969,0,0,1,10.035,15,14.75,14.75,0,0,1,5.5,10.463,14.969,14.969,0,0,1,3.174,3.905,1.513,1.513,0,0,1,4.679,2.256H6.948a1.513,1.513,0,0,1,1.513,1.3,9.712,9.712,0,0,0,.529,2.125,1.513,1.513,0,0,1-.34,1.6L7.69,8.24a12.1,12.1,0,0,0,4.538,4.538l.961-.961a1.513,1.513,0,0,1,1.6-.34,9.712,9.712,0,0,0,2.125.529A1.513,1.513,0,0,1,18.211,13.542Z" transform="translate(-2.417 -0.672)" fill="none" stroke="#31006f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
          </svg>
        </i>
        مدير حسابك
      </button>
      <div class="account-manage" :class="accountManageActive ? 'account-manage-active' : ''"  ref="dropdownMenuAccount">
        <img src="@/assets/images/elements/user2.svg" class="mr-4" alt="User" width="39.75">
        <span class="active"></span>
        <div class="content">
          <h6>{{account_manager.name}}</h6>
          <h6 class="phone" v-if="account_manager.phone1">{{account_manager.phone1 ? account_manager.phone1 : "لا يوجد"}}</h6>
        </div>
      </div>
    </div>

    <vx-tooltip text="محادثات" position="bottom" delay=".2s">
      <div data-tour-step="8" style="border-radius: 4px;" @click="googleEventTrack('الضغط على ايقون المحادثة', 'navbar', 'chat_icon_in_navbar')">
        <feather-icon v-if="unreadMessages > 0 && unreadMessagesRealTime == null" :badge="unreadMessages" svgClasses="'h-6 w-6" class="p-2 cursor-pointer" icon="MessageCircleIcon" @click="$router.push('/apps/chat/id='+'').catch(() => {})" />
        <feather-icon v-else-if="unreadMessagesRealTime > 0" :badge="unreadMessagesRealTime" svgClasses="'h-6 w-6" class="p-2 cursor-pointer" icon="MessageCircleIcon" @click="$router.push('/apps/chat/id='+'').catch(() => {})" />
        <feather-icon v-else svgClasses="'h-6 w-6" class="p-2 cursor-pointer" icon="MessageCircleIcon" @click="$router.push('/apps/chat/id='+'').catch(() => {})" />
      </div>
    </vx-tooltip>

    <search-bar />

  </div>
</template>

<script>
import SearchBar from './SearchBar.vue'
import { mapActions, mapGetters } from "vuex"
import FeatherIcon from '../../../../components/FeatherIcon.vue'
import gTag from "@/mixins/gtag.js"

export default {
  mixins: [gTag],
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  components: {
    SearchBar,
    FeatherIcon
  },
  data () {
    return {
      showBookmarkPagesDropdown : false,
      accountManageActive: false,
      unreadMessages: this.count_unread_ticket ? this.count_unread_ticket : 0,
      unreadMessagesRealTime: null,
      unreadTicket: 0,
      unreadOrder: 0,
      unreadMission: 0
    }
  },
  watch: {
    '$route' () {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
    }
  },
  computed: {
    ...mapGetters('dataList', ['account_manager']),
    ...mapGetters('ticket', ['count_unread_ticket']),
    navbarSearchAndPinList () {
      return {pages: this.$store.state.navbarSearchAndPinList['pages']}
    },
    starredPages () {
      return this.$store.state.starredPages
    },
    starredPagesLimited: {
      get () {
        return this.starredPages.slice(0, 10)
      },
      set (list) {
        this.$store.dispatch('arrangeStarredPagesLimited', list)
      }
    },
    starredPagesMore: {
      get () {
        return this.starredPages.slice(10)
      },
      set (list) {
        this.$store.dispatch('arrangeStarredPagesMore', list)
      }
    },
    textColor () {
      return {'text-white': this.$store.state.mainLayoutType === 'vertical' && this.navbarColor !== (this.$store.state.theme === 'dark' ? '#10163a' : '#fff') }
    },
    windowWidth (){ return this.$store.state.windowWidth}
  },
  methods: {
    ...mapActions('dataList', ['fetchAccountManager']),
    ...mapActions('ticket', ['countUnreadTicket']),
    outside () {
      this.showBookmarkPagesDropdown = false
    },
    documentClick(e){
      let el = this.$refs.dropdownMenu
      let el2 = this.$refs.dropdownMenuAccount
      let target = e.target
      if ( el !== target && el2 !== target ) {
        this.accountManageActive=false
      }
    },
    calcUnred(){
      this.unreadMessagesRealTime = this.unreadTicket + this.unreadOrder + this.unreadMission
    }
  },
  mounted() {
    const userId = JSON.parse(localStorage.getItem('userData')).userId
    this.$socket.emit('join', {name: `unreaded-${userId}-created`})
    this.$socket.emit('join', {name: `unreaded-order-${userId}-created`})
    this.$socket.emit('join', {name: `unreaded-mission-${userId}-created`})
    this.$socket.on(`unreaded-${userId}-created`, (data) => {
      this.unreadTicket = parseFloat(data.body.tickets)
      this.calcUnred()
    })
    this.$socket.on(`unreaded-order-${userId}-created`, (data) => {
      this.unreadOrder = parseFloat(data.body.orders)
      this.calcUnred()
    })
    this.$socket.on(`unreaded-mission-${userId}-created`, (data) => {
      this.unreadMission = parseFloat(data.body.missions)
      this.calcUnred()
    })
  },
  unmounted() {
    this.$socket.emit('leave', {name: `unreaded-${userId}-created`})
    this.$socket.emit('leave', {name: `unreaded-order-${userId}-created`})
    this.$socket.emit('leave', {name: `unreaded-mission-${userId}-created`})
    this.$socket.off(`unreaded-${userId}-created`)
    this.$socket.off(`unreaded-order-${userId}-created`)
    this.$socket.off(`unreaded-mission-${userId}-created`)
  },
  async created(){
    this.fetchAccountManager()
    await this.countUnreadTicket()
    this.unreadMessages = this.count_unread_ticket.tickets + this.count_unread_ticket.orders + this.count_unread_ticket.missions
    document.addEventListener('click', this.documentClick)
  },
  directives: {
    'click-outside': {
      bind (el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
          /* eslint-disable no-mixed-operators */
          if (bubble || !el.contains(e.target) && el !== e.target) {
          /* eslint-enable no-mixed-operators */
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },

      unbind (el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null

      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';

.account-manage-btn{
  background-color: $mainColor2;
  padding: 5px 12px;
  border: none;
  font-family: "cairo";
  border-radius: 4px;
  color: #31006F;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px;
  i{
    height: 18px;
    margin-right: 8px;
  }
}

.account-manage{
  padding: 12px 9px;
  box-shadow: 0px 4px 24px #0000000D;
  background-color: #FFF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 47px;
  width: 2165px;
  max-width: 265px;
  height: 0px;
  opacity: 0;
  transition: 0.3s;
  img{
    margin-right: 9px;
    border-radius: 50%;
    border: 1px solid #28C76F80;
    position: relative;
  }
  .active{
    content: '';
    width: 10px;
    height: 10px;
    background-color: #28C76F;
    position: absolute;
    border-radius: 50%;
    left: 40px;
    bottom: 20px;
    border: 1px solid #FFF;
    box-shadow: 0px 2px 4px #28C76F41;
  }
  .content{
    h6{
      color: $mainColor1;
      font-size: 1rem;
      line-height: 21px;
      font-weight: bold;
    }
    .phone{
      color: #6E6B7B;
      font-weight: 500;
    }
  }
}
.account-manage-active{
  height: 75px;
  z-index: 9;
  opacity: 1;
}
[dir] .feather-icon-badge {
    background-color: #ea4242 !important;
    top: 0px !important;
    right: -10px !important;
    padding: 0px 5px;
    font-size: 10px !important;
    height: auto !important;
    width: fit-content !important;
}
</style>