export default {
  computed: {
    hasAccess() {
      if (this.$acl.check("isAdminCompany") || this.$acl.check("isAdmin")) {
        return true;
      } else {
        return false;
      }
    },
  },
};
