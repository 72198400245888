<template>
<div >
    <div v-if="ticketPageIndex == 1">
        <div class="flex items-center justify-between mb-4">
            <h6>اختر نوع الرسالة التي تود البدأ بها</h6>
            <p class="instractions" @click="setTicketPageIndex('instractions')">
                تعليمات
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#EE3838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </p>
        </div>
        <div class="types">
            <p v-for="(theType, index) in allStatus" :key="index" @click="setTypeTicket(theType.type), nextPage(), getTypes(theType.type)">
                <i class="plus-icon">
                    <feather-icon icon="PlusIcon" class="cursor-pointer"></feather-icon>
                </i>
                {{theType.name}}
            </p>
        </div>
    </div>
    <div v-else-if="dataCreatTicket.typeTicket == allTypes.complaint && (ticketPageIndex == 2 || ticketPageIndex == 3)" class="types">
        <div v-if="loading" class="loading-types"><SpinnerColor /></div>
        <div v-else>
            <div v-if="ticketPageIndex == 2">
                <h6>اختر نوع المشكلة التي تواجهك لتساعدنا لحلها في أسرع وقت</h6>
                <p v-for="(complain_type, index) in types" :key="index" class="ticket-type" @click="setTypeProblem(complain_type.id), nextPage(), setComplainTypeId(complain_type.id), setRelatedModel(complain_type.related_model)">
                    {{complain_type.name_ar}}
                </p>
            </div>
            <div v-else v-for="(complain_type, index) in types" :key="index">
                <div v-if="typeProblem == complain_type.id" class="types">
                    <h6>ما هي المشكلة التي تواجهك</h6>
                    <p v-for="(title, index) in complain_type.titles" :key="index" class="ticket-type" @click="setSubTitle(title.title_ar), nextPage(), setComplainTypeTitleId(title.id)">
                        {{title.title_ar}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="dataCreatTicket.typeTicket == allTypes.inquiry && ticketPageIndex == 2" class="types">
        <div v-if="loading" class="loading-types"><SpinnerColor /></div>
        <div v-else>
            <h6>اختر نوع الاستفسار الذي تريدنا ان نجيبك علية في أسرع وقت</h6>
            <p v-for="(inquiry_type, index) in types" :key="index" class="ticket-type" @click="nextPage(), setInquiryTypeId(inquiry_type.id), setSubTitle(inquiry_type.name_ar)">
                {{inquiry_type.name_ar}}
            </p>
        </div>
    </div>
    <div style="height: 100%;" v-else-if="dataCreatTicket.typeTicket == allTypes.suggestion && ticketPageIndex == 2">
        <GratitudeAndSuggestionTicket :type="allTypes.suggestion" title="اقتراح" />
    </div>
    <div style="height: 100%;" v-else-if="dataCreatTicket.typeTicket == allTypes.gratitude && ticketPageIndex == 2">
        <GratitudeAndSuggestionTicket :type="allTypes.gratitude" title="شكر" />
    </div>
    <div v-else-if="checkCount(3, 4)" class="types">
        <SubTypeTicket
            @stopLoading="hideAppointSomeone()"
            :dataCreatTicket="dataCreatTicket" />
    </div>
    <div v-else-if="checkCount(4, 5)" class="appoint-someone">
        <lottie-animation
            ref="anim"
            :animationData="require('./lottie-loader.json')"
            :loop="true"
            :speed="1"
        />
        <h6>جاري تعيين شخص مختص 
        لحل مشكلتك...</h6>
    </div>
    <ChatTicket v-else-if="checkCount(5, 6)" />
    <instractionsList v-else-if="ticketPageIndex == 'instractions'" />
</div>


</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import ChatTicket from './ChatTicket'
import { mapActions, mapGetters, mapState } from 'vuex'
import ticketStatus from '@/views/status/ticketStatus'
import SubTypeTicket from './SubTypeTicket'
import GratitudeAndSuggestionTicket from './GratitudeAndSuggestionTicket'
import SpinnerColor from '@/views/components/table-components/SpinnerColor2.vue'
import instractionsList from './instractions.vue'

export default {
    data() {
        return {
            allStatus: ticketStatus.status,
            allTypes: ticketStatus.types,
            loading: false,
            show:null,
        }
    },
    components: {
        LottieAnimation,
        ChatTicket,
        SubTypeTicket,
        GratitudeAndSuggestionTicket,
        SpinnerColor,
        instractionsList
    },
    computed: {
        ...mapGetters('ticket', ['types']),
        ...mapState('ticket', ['ticketPageIndex', 'typeProblem', "dataCreatTicket"])
    },
    methods: {
        ...mapActions('ticket',
        ['complainsTypes',
        'createTicket',
        'inquiriesTypes',
        'toggleShowTicket',
        'setTicketPageIndex',
        'nextPage',
        'setTypeProblem',
        'setSubTitle',
        'setComplainTypeId',
        'setComplainTypeTitleId',
        'setTypeTicket',
        'setRelatedModel',
        'setInquiryTypeId']),
        hideAppointSomeone(){
            setTimeout(() => {
                if(this.ticketPageIndex == 5 || this.ticketPageIndex == 4){
                    this.nextPage()
                }
            }, 2000);
        },
        async getTypes(type){
            if(type == this.allTypes.complaint){
                this.loading = true
                await this.complainsTypes()
                this.loading = false
            }else if(type == this.allTypes.inquiry){
                this.loading = true
                await this.inquiriesTypes()
                this.loading = false
            }
        },
        checkCount(num1, num2){
            if((this.ticketPageIndex == num1 && this.dataCreatTicket.typeTicket == this.allTypes.inquiry) || (this.ticketPageIndex == num2 && this.dataCreatTicket.typeTicket == this.allTypes.complaint)){
                return true
            }else{
                return false
            }
        }
    },    

    mounted () {
        this.$watch(
            () => {
                return this.$tours.navMenuTour.currentStep;
            },
            (val) => {
                if (val == 4) {
                    this.toggleShowTicket()
                }else if (val == 5) {
                    this.setTicketPageIndex(2)
                    this.setTypeTicket(2)
                    this.getTypes(2)
                }else if (val == 6) {
                    this.setTicketPageIndex(4)
                    this.setTypeProblem(1)
                    // this.dataCreatTicket.inquiryTypeId = ''
                    this.setComplainTypeId(1)
                    this.setTypeTicket(2)
                    this.setRelatedModel(0)
                    this.setSubTitle('تأخير تسليم')
                    this.$router.push({ path: '/apps/chat/id' })
                }else if (val == 8) {
                    this.toggleShowTicket()
                    const nextStep = document.querySelector('.v-step__button')
                    nextStep.addEventListener('click', ()=>{
                        this.$router.push({ path: '/addMission' })
                    })
                }
            }
        )
    },

}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/vuexy/_supportTicket.scss';
</style>